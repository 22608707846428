// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import AccountPopover from './AccountPopover';
import Searchbar from './Searchbar';
import ReportsLable from './ReportsLabel';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 70;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  background: 'none',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('lg')]: {
    // width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 3),
    paddingTop: theme.spacing(7),
  },
}));

// ----------------------------------------------------------------------

export default function Header() {
  const { pathname } = useLocation();

  const isDashboard = pathname === '/dashboard/app';
  const isReportsPage = pathname.startsWith('/dashboard/reports');

  return (
    <StyledRoot>
      <StyledToolbar>
        {isDashboard && <Searchbar /> }
         {isReportsPage &&<ReportsLable /> }
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
