/* eslint-disable no-undef */
import { Box, Typography, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker as antDatePicker } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

// import Iconify from '../iconify/Iconify';

const { RangePicker } = antDatePicker;

export default function DateRangePicker({ onDateChange, label, sx, startDate, endDate }) {
  const location = useLocation();
const currentPath = location.pathname;

const disabledDates = (current) => {
  if (currentPath.includes('/reports/preshipment')) {
    const fiveDaysAgo = dayjs().subtract(5, 'day');
    return current.isBefore('2023-06-19') || current.isAfter(fiveDaysAgo);
  } else if (currentPath.includes('/reports/intransit')) {
    const tenDaysAgo = dayjs().subtract(10, 'day');
    return current.isBefore('2023-06-19') || current.isAfter(tenDaysAgo);
  } else {
    return current.isBefore('2023-06-19') || current.isAfter(dayjs());
  }
}


  const handleDateChange = (dates) => {
    setSelectedDates(dates);
    onDateChange(dates);
  };
  const StyledDatePicker = styled(RangePicker)(({ theme }) => ({
    height: theme.spacing(5.2),
    width: 267,
    '& .ant-picker-suffix, .ant-picker-active-bar': {
      // display: 'none',
    },
    '& .ant-picker-input': {
      width: 86,
    },
    '& .ant-picker-input > input': {
      fontSize: 14,
      fontFamily: 'Whitney !important',
      fontWeight: 500,
      color: theme.palette.secondary.light,
    },
    '& .ant-picker-input > input:placeholder-shown': {
      // display: 'none',
    },
    '& .ant-picker .ant-picker-clear': {
      insetInlineEnd: "50px",
    },
    '& .ant-picker-range .ant-picker-clear': {
      insetInlineEnd: "50px",
    },

    '& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right': {
      color: theme.palette.common.black,
    },
    '& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right > svg': {
      width: 12,
    },
    //tild image of date picker 
    '& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right > svg > path': {
      d: "path('M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z')",
    },
    [theme.breakpoints.down('lg')]: {
      height: 33,
      width: 200,
      '& .ant-picker-input > input': {
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.secondary.light,
      },
      '& .ant-picker-range-separator': {
        padding: 0,
      },
      '.ant-picker-range .ant-picker-clear': {
        insetInlineEnd: "25px",
      },
      '& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right > svg': {
        width: 8,
      },
    },
  }));

  const StyledDivider = styled(Divider)(({ theme }) => ({
    borderStyle: 'line',
    zIndex: 5,
    marginTop: 7,
    marginBottom: 7,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  }));
  const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Whitney Semibold',
    [theme.breakpoints.down('lg')]: {
      fontSize: 10,
      fontWeight: 500,
    },
  }));


  return (
    <Stack direction="row" sx={{ ...sx, display: 'flex', alignItems: 'center' }}>
      {label && (
        <>
          <Box
            sx={{
              color: 'common.black',
              backgroundColor: 'common.white',
              px: { lg: 2, sm: 1 },
              py: { lg: 1, sm: 1 },
              mr: { lg: -0.5, sm: -1 },
              zIndex: 2,
              borderTopLeftRadius: { lg: 9, sm: 6 },
              borderBottomLeftRadius: { lg: 9, sm: 6 },
              borderLeft: '1px solid #d9d9d9',
              borderTop: '1px solid #d9d9d9',
              borderBottom: '1px solid #d9d9d9',
              '& ::after': {},
            }}
            className="date-range-picker-label"
          >
            <StyledTypography>{label}</StyledTypography>
          </Box>
          <StyledDivider orientation="vertical" flexItem />
        </>
      )}
      <StyledDatePicker
        format="MM-DD-YYYY"
        allowEmpty
        onChange={(dates) => onDateChange(dates)}
        value={startDate && ([dayjs(startDate), dayjs(endDate)])}
        disabledDate={disabledDates}
        // {(current) => current && (current.isBefore('2023-06-19') || current.isAfter(dayjs()))}
        suffixIcon={<CalenderIcon/>}
      />
    </Stack>
  );
}

 export const CalenderIcon = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'primary.lightbg',
          color: 'primary.lightIcon',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight:"-19px",
          height: { sm: 33, lg: 41 },
          width: { sm: 32, lg: 48 },
          zIndex: 1,
          borderTopRightRadius: { lg: 9, sm: 6 },
          borderBottomRightRadius: { lg: 9, sm: 6 },
        }}
      >
        <img src="/assets/icons/datepicker/calender.svg" width={18} alt="" />
      </Box>
      </>
  )
}

DateRangePicker.propTypes = {
  onDateChange: PropTypes.func,
  label: PropTypes.string,
  sx: PropTypes.shape({}),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};
