import { styled } from '@mui/material/styles';

import { Typography, Box, Card, Grid, FormLabel, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Iconify from '../../components/iconify';
import React, { useState } from 'react';
import {
  InputAdornment,
  IconButton
} from '@mui/material';

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  item: PropTypes.object.isRequired,
};

function AppWidgetSummary({ item }) {
  const StyledIcon = styled('div')(() => ({
    display: 'flex',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  return (
    <Card
      sx={{
        py: { lg: 2, sm: 1 },
        px: { lg: 3, sm: 1 },
        boxShadow: 0,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        width: { lg: 214, sm: 113 },
        height: { lg: 116, sm: 60 },
        borderRadius: 1.5,
      }}
    >
      <Box
        container
        sx={{
          width: 30,
        }}
      >
        <StyledIcon
          sx={{
            width: { lg: 50, sm: 30 },
            height: { lg: 50, sm: 30 },
          }}
        >
          <img src={item.icon} alt="" />
        </StyledIcon>
      </Box>
    </Card>
  );
}

ConfigurationDataCard.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.array,
  sectionIndex: PropTypes.number,
  control: PropTypes.func,
  updateSaveEnableState: PropTypes.func,
};

const validationRules = {
  required: 'This field is required',
}

export default function ConfigurationDataCard({ title, fields, sectionIndex, control, updateSaveEnableState }) {

  const [showIterableApiKey, setShowIterableApiKey] = useState(false);
  const [showUpsPassword, setShowUpsPassword] = useState(false);
  const [showAccessToken, setShowAccessToken] = useState(false);
  const [showMagentoAPIKey, setShowMagentoAPIKey] = useState(false);
  const [showClientID, setShowClientID] = useState(false);
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [showUSPSClientID, setShowUSPSClientID] = useState(false);
  const [showUSPSClientSecret, setShowUSPSClientSecret] = useState(false);

  const StyledRoot = styled('div')(() => ({
    maxWidth: '100%',
  }));

  const validationPatterns = {
    ITERABLE_WORKFLOW_ID: {
      value: "[0-9]",
      message: 'This field contain only numbers',
    },
  };

  const getInputFieldType = (field) => {
    switch (field.name) {
      case 'ITERABLE_API_KEY':
        return showIterableApiKey ? 'text' : 'password';
      case 'SERVICE_UPS_PASSWORD':
        return showUpsPassword ? 'text' : 'password';
      case 'ACCESS_TOKEN':
        return showAccessToken ? 'text' : 'password';
      case 'MAGENTO_API_KEY':
        return showMagentoAPIKey ? 'text' : 'password';
      case 'SERVICE_UPS_CLIENT_ID':
        return showClientID ? 'text' : 'password';
      case 'SERVICE_UPS_CLIENT_SECRET':
        return showClientSecret ? 'text' : 'password';
      case 'SERVICE_USPS_CLIENT_ID':
        return showUSPSClientID ? 'text' : 'password';
      case 'SERVICE_USPS_CLIENT_SECRET':
        return showUSPSClientSecret ? 'text' : 'password';
      default:
        return 'text';
    }
  };
  const renderInputAdornment = (field) => {
    if (
      field.name === 'ITERABLE_API_KEY' ||
      field.name === 'SERVICE_UPS_PASSWORD' ||
      field.name === 'ACCESS_TOKEN' ||
      field.name === 'MAGENTO_API_KEY' ||
      field.name === 'SERVICE_UPS_CLIENT_ID' ||
      field.name === 'SERVICE_UPS_CLIENT_SECRET' ||
      field.name === 'SERVICE_USPS_CLIENT_ID' ||
      field.name === 'SERVICE_USPS_CLIENT_SECRET'
    ) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              if (field.name === 'ITERABLE_API_KEY') setShowIterableApiKey(!showIterableApiKey);
              if (field.name === 'SERVICE_UPS_PASSWORD') setShowUpsPassword(!showUpsPassword);
              if (field.name === 'ACCESS_TOKEN') setShowAccessToken(!showAccessToken);
              if (field.name === 'MAGENTO_API_KEY') setShowMagentoAPIKey(!showMagentoAPIKey);
              if (field.name === 'SERVICE_UPS_CLIENT_ID') setShowClientID(!showClientID);
              if (field.name === 'SERVICE_UPS_CLIENT_SECRET') setShowClientSecret(!showClientSecret);
              if (field.name === 'SERVICE_USPS_CLIENT_ID') setShowUSPSClientID(!showUSPSClientID);
              if (field.name === 'SERVICE_USPS_CLIENT_SECRET') setShowUSPSClientSecret(!showUSPSClientSecret);
            }}
            edge="end"
          >
            <Iconify icon={getInputFieldType(field) === 'text' ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
          </IconButton>
        </InputAdornment>
      );
    }
    else {
      return null;
    }
  };

  return (
    <StyledRoot>
      <Box sx={{ py: 2 }}>
        <Typography variant="h4" sx={{ color: 'common.black', pl: 2, py: 1, lineHeight: 0.5, fontSize: { lg: 24, sm: 16, md: 16 } }}>
          {title}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: { lg: 'start', sm: 'start' },
          // my: 2,
          pl: 2,
        }}
      >
        {fields.map((formField, index) => (
          <Grid item xs={6} lg={4} key={index}>
            <FormLabel
              variant="body2"
              htmlFor={`input${sectionIndex}-${index + 1}`}
              sx={{ color: 'secondary.light', fontSize: { lg: 14, md: 12, sm: 12 }, fontFamily: 'Whitney' }}
            >
              {formField.label}
              <Box component="span" sx={{ color: 'warning.red' }}>
                *
              </Box>
            </FormLabel>
            <Controller
              name={formField.label}
              control={control}
              rules={{
                required: !formField?.notRequire ? validationRules.required : false,
                pattern: validationPatterns[formField.label]?.value,
                validate: formField.label === 'UPS_WAIT_HOURS' || formField.label === 'USPS_WAIT_HOURS' ? {
                  minimumHours: (value) => parseFloat(value) >= 60 || 'Waiting hours should be minimum 60 hours',
                } : formField?.validate ? { validate: (value) => formField?.validate(value) } : null,
              }}
              render={({ field, fieldState }) =>
                formField.type === 'toggle' ?
                  <Button className="lastInputBox" position="start" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ color: 'black' }}>
                      {field?.value ? 'Enable' : 'Disable'}
                    </Typography>
                    <Iconify position="end"
                      style={{ color: field?.value ? '#479DA6' : 'grey', fontSize: '100px', height: '50px', width: '50px' }}
                      onClick={() => {
                        field?.onChange(!field?.value);
                        updateSaveEnableState(true);
                      }}
                      icon={field?.value ? 'eva:toggle-right-fill' : 'eva:toggle-left-fill'}
                    />
                  </Button>
                  : <TextField
                    {...field}
                    type={getInputFieldType(field)}
                    sx={{
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-input': { color: 'common.black', padding: '10px' },
                      '& .MuiInputLabel-shrink': { display: 'none' },
                      '& .MuiOutlinedInput-notchedOutline': { borderColor: '1px solid #929292' },
                    }}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={
                      fieldState.invalid && fieldState.error?.type === 'pattern' ? validationPatterns[field.label]?.message || 'Invalid input' : fieldState.error?.message || ''
                    }
                    InputProps={{
                      endAdornment: renderInputAdornment(field),
                    }}
                    onKeyUp={(event) => {
                      updateSaveEnableState(true);
                    }}
                  />
              }
            />
          </Grid>
        ))}
      </Grid>
    </StyledRoot>
  );
}