import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getData from '../../dataFetcher/getDataFetcher';
import putData from '../../dataFetcher/putDataFetcher';

const BaseUrl = process.env.REACT_APP_BACKEND_URL;


let url = `${BaseUrl}configuration/general`;

const initialState = {
  configData: {},
  isConfigUpdated: false,
};

export const getConfig = createAsyncThunk('config/getConfiguration', async (token) => {
  var parms = null;
  const response = await getData(url,parms, token);
  return response;
});


// post action

export const putConfigData = createAsyncThunk(
    'config/putConfiguration',
    async (data) => {
      const response = await putData(url, data, data.token);
      return data;
    }
  );

//reducer
export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      state.configData = action.payload;
    },
    resetConfigState: (state) => initialState,
  },

  extraReducers: (builder) => {
    builder
    .addCase(getConfig.fulfilled, (state, action) => {
      state.configData = action.payload.data;
      state.isConfigUpdated = false;
    })
    .addCase(getConfig.rejected, (state, action) => {
    })
    .addCase(putConfigData.rejected, (state, action) => {
      state.isConfigUpdated = false;
    })
    .addCase(putConfigData.fulfilled, (state, action) => {
      state.configData = action.payload;
      state.isConfigUpdated = true;
    });
  },
});

export const getConfigData = (state) => state.config.configData;
export const getConfigUpdated = (state) => state.config.isConfigUpdated;
export const { updateConfig, resetConfigState } = configSlice.actions;
export default configSlice.reducer;
