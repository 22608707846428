import React from 'react';
import './CircularLoader.css';

function CircularLoader() {
  return (
    <div className="backdropMain">
      <div className="loading">
        <div className="arc" />
        <div className="arc" />
        <div className="arc" />
      </div>
    </div>
  );
}

export default CircularLoader;
