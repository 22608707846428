import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const tableHeadTextStyle = {
  color: 'secondary.light',
  fontFamily: ' Whitney Semibold',
  fontSize: '21px',
  bgcolor: '#D9E1E2',
  //   opacity: 0.2,
};

const tableCellTextStyle = {
  color: '#1C272A',
  fontFamily: 'Whitney',
  fontSize: '21px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.1px',
};

TransitTotalTableDataCard.propTypes = {
  title: PropTypes.string,
  cardItems: PropTypes.array,
  onClickRow: PropTypes.func,
};

export default function TransitTotalTableDataCard({ title, cardItems, onClickRow }) {
  const cardValues = cardItems ?? [];
  return (
    <Box sx={{ padding: 'none' }}>
      <Grid
        sx={{
          py: 2,
          height: 50,
          display: 'flex',
          alignItems: 'center',
          //   bgcolor: 'common.white',
          borderTopLeftRadius: 9,
          borderTopRightRadius: 9,
        }}
      >
        <Typography variant="ReportsCardValue" sx={{ color: 'common.black' }}>
          {title}
        </Typography>
      </Grid>

      <TableContainer
        className="statistics-data"
        component={Paper}
        sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ bgcolor: 'secondary.main' }}>
              <TableCell sx={{ ...tableHeadTextStyle, width: '20%' }}>Shipment Count</TableCell>
              <TableCell sx={{ ...tableHeadTextStyle, width: '20%' }} display="flex" aria-label="center">
                {' '}
                Average(days){' '}
              </TableCell>
              <TableCell sx={{ ...tableHeadTextStyle, width: '20%' }}>Min(days) </TableCell>
              <TableCell sx={{ ...tableHeadTextStyle, width: '20%' }}>Max(days)</TableCell>
            </TableRow>
          </TableHead>

          <TableBody sx={{ borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}>
            <TableRow>
              <TableCell sx={{ ...tableCellTextStyle, width: '20%' }}>{cardValues[0].PlaceCount}</TableCell>
              <TableCell sx={{ ...tableCellTextStyle, width: '20%' }}>{cardValues[0].Average}</TableCell>
              <TableCell sx={{ ...tableCellTextStyle, width: '20%' }}>{cardValues[0].Min}</TableCell>
              <TableCell sx={{ ...tableCellTextStyle, width: '20%' }}>{cardValues[0].Max}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
