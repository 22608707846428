import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/* eslint-disable */
import { Container, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getUserInfo } from '../features/user/userState';
import { useAppSelector } from '../hooks/hooks';
import ShippingStatisticsDataCard from '../components/data-card/ShippingStatisticsDataCard';
import ShippingMatricsDataCard from '../components/data-card/ShippingMatricsDataCard';
import EmailStatisticsDataCard from '../components/data-card/EmailStatisticsDataCard';
import DateRangePicker from '../components/date-picker/DateRangePicker';
import { async } from 'q';
import { fetchScanStatus, fetchShippingStatistic } from '../features/scan/scanState';
import { useDispatch } from 'react-redux';
import { datePstToUtc } from 'src/utils/core';
import { fetchReplacementOrders } from 'src/features/replacementOrder/replacementState';

export default function DashboardAppPage() {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(dayjs().format('MM/DD/YYYY'));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useAppSelector(getUserInfo);

  useEffect(() => {
    dispatch(fetchScanStatus({ startDate: startDate, endDate: endDate, token: userInfo?.accessToken }));
    dispatch(fetchShippingStatistic({ startDate: startDate, endDate: endDate, token: userInfo?.accessToken }));
  }, []);

  const mainIcon = (name) => `/assets/icons/dashboardIcons/${name}.svg`;

  const [userName, setUserName] = useState('');

  const metrics = useAppSelector((state) => state.scan.metrics);
  const scanStatistics = useAppSelector((state) => state.scan.scan_statistics);
  const statusStatistics = useAppSelector((state) => state.scan.status_statistics);

  useEffect(() => {
    setUserName(userInfo?.user?.user_username);
  }, []);

  const headTextStyle = {
    color: 'primary.main',
    my: 2,
    fontWeight: 500,
    fontFamily: 'Whitney',
    fontSize: { sm: 26, md: 26, lg: 36 },
  };

  const shippingMatrics = [
    {
      icon: mainIcon('total_expected_deliveries'),
      title: 'Expected Deliveries',
      total: metrics?.expected_delivery || 0,
      status: 'Expected Deliveries',
    },
    {
      title: 'Delayed',
      total: metrics?.delayed || 0,
      icon: mainIcon('Delayed'),
      status: 'Delayed',
    },
    {
      title: 'Delivery Attempt',
      total: metrics?.delivery_attempt || 0,
      icon: mainIcon('DeliveryAttempt'),
      status: 'Delivery Attempt',
    },
    {
      title: 'Alerts',
      total: metrics?.alert || 0,
      icon: mainIcon('Alerts'),
      status: 'Alert',
    },
    {
      title: 'Returned',
      total: metrics?.returned || 0,
      icon: mainIcon('Returned'),
      status: 'Returned',
    },
  ];

  const EmailStatistics = [
    {
      icon: mainIcon('TotalDB'),
      title: 'Total in Database',
      total: scanStatistics?.db_count || 0,
      status: 'Total in Database',
    },
    {
      title: 'Scanned Successfully',
      total: scanStatistics?.scanned_count || 0,
      icon: mainIcon('ScannedSuccessfully'),
      subtitle: `${scanStatistics?.scanned_per || 0.0}%`,
      status: 'Scanned Successfully',
    },
    {
      title: 'No Scan',
      total: scanStatistics?.unscanned_count || 0,
      icon: mainIcon('NoScan'),
      subtitle: `${scanStatistics?.unscanned_per || 0.0}%`,
      status: 'No Scan',
    },
    {
      title: 'Scanned with Estimated Delivery Date',
      total: scanStatistics?.estimated_date_count || 0,
      icon: mainIcon('scannedwithestimatedDeliveryDate'),
      status: 'Scanned with Estimated Delivery Date',
    },
    {
      title: 'Scanned without Estimated Delivery Date',
      total: scanStatistics?.no_estimated_date_count || 0,
      icon: mainIcon('ScannedwithoutEstimatedDeliveryDate'),
      status: 'Scanned without Estimated Delivery Date',
    },
    {
      title: 'Delivered Without Scan',
      total: scanStatistics?.delivered_count || 0,
      icon: mainIcon('deliveredwithoutscan'),
      subtitle: `${scanStatistics?.delivered_per || 0.0}%`,
      status: 'Delivered Without Scan',
    },
  ];

  const shippingStatistics = [
    {
      icon: mainIcon('alertStatistics'),
      status: 'Alert',
      volume: statusStatistics?.find((status) => status.status === 'Alert')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Alert')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('agentpickup'),
      status: 'Available for Agent Pickup',
      volume: statusStatistics?.find((status) => status.status === 'Available for Agent Pickup')?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === 'Available for Agent Pickup')?.percentage || '0.00'
      }%`,
    },
    {
      icon: mainIcon('avialableforpickup'),
      status: 'Available for Pickup',
      volume: statusStatistics?.find((status) => status.status === 'Available for Pickup')?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === 'Available for Pickup')?.percentage || '0.00'
      }%`,
    },
    {
      icon: mainIcon('delivered'),
      status: 'Delivered',
      volume: statusStatistics?.find((status) => status.status === 'Delivered')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Delivered')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('deliveredtoagent'),
      status: 'Delivered to Agent',
      volume: statusStatistics?.find((status) => status.status === 'Delivered to Agent')?.volume || 0,
      percentage: `${
        statusStatistics?.find((status) => status.status === 'Delivered to Agent')?.percentage || '0.00'
      }%`,
    },
    {
      icon: mainIcon('deliveryattemptStatistics'),
      status: 'Delivery Attempt',
      volume: statusStatistics?.find((status) => status.status === 'Delivery Attempt')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Delivery Attempt')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('intransit'),
      status: 'In Transit',
      volume: statusStatistics?.find((status) => status.status === 'In Transit')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'In Transit')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('outfordelivery'),
      status: 'Out for Delivery',
      volume: statusStatistics?.find((status) => status.status === 'Out for Delivery')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Out for Delivery')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('preshipment'),
      status: 'Pre-Shipment',
      volume: statusStatistics?.find((status) => status.status === 'Pre-Shipment')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Pre-Shipment')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('returnedStatistics'),
      status: 'Returned',
      volume: statusStatistics?.find((status) => status.status === 'Returned')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Returned')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('forwarded'),
      status: 'Forwarded',
      volume: statusStatistics?.find((status) => status.status === 'Forwarded')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Forwarded')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('unknown'),
      status: 'Unknown',
      volume: statusStatistics?.find((status) => status.status === 'Unknown')?.volume || 0,
      percentage: `${statusStatistics?.find((status) => status.status === 'Unknown')?.percentage || '0.00'}%`,
    },
    {
      icon: mainIcon('totalpackages'),
      status: 'Total Packages',
      volume: statusStatistics?.reduce((total, status) => total + status.volume, 0) || 0,
      percentage: '',
    },
  ];

  const onDateChange = (dates) => {
    if (dates != null) {
      const formattedStartDate = dayjs(dates[0]).format('MM/DD/YYYY');
      const formattedEndDate = dayjs(dates[1]).format('MM/DD/YYYY');
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);

      if (formattedStartDate !== '' && formattedEndDate !== '') {
        scanDetails(formattedStartDate, formattedEndDate);
      }
    }
  };

  const scanDetails = async (formattedStartDate, formattedEndDate) => {
    dispatch(
      fetchScanStatus({
        startDate: datePstToUtc(formattedStartDate),
        endDate: datePstToUtc(formattedEndDate),
        token: userInfo?.accessToken,
      })
    );
    dispatch(
      fetchShippingStatistic({
        startDate: datePstToUtc(formattedStartDate),
        endDate: datePstToUtc(formattedEndDate),
        token: userInfo?.accessToken,
      })
    );
  };
  const handleOnClick = (item) => {
    navigate(
      `/dashboard/tracking/filter/${item.status}/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`
    );
  };

  const onFirstScanHandleOnClick = (item) => {
    navigate(
      `/dashboard/tracking/scan_filter/${item.status}/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`
    );
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Shipping Insights </title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Typography sx={headTextStyle}>Welcome {userName}!</Typography>
          <DateRangePicker
            label="Label Creation Date"
            onDateChange={onDateChange}
            startDate={startDate}
            endDate={endDate}
          />
        </Stack>
        <ShippingMatricsDataCard title="Shipping Metrics" cardItems={shippingMatrics} handleOnClick={handleOnClick} />
        <EmailStatisticsDataCard
          sx={{ flexWrap: 'wrap' }}
          title="First Scan Email Statistics"
          cardItems={EmailStatistics}
          onFirstScanHandleOnClick={onFirstScanHandleOnClick}
        />
        <ShippingStatisticsDataCard
          title="Shipping Statistics"
          cardItems={shippingStatistics}
          onClickRow={handleOnClick}
        />
      </Container>
    </>
  );
}
