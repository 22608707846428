import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateColumnPreferences } from './userColPreferenceApi';


export const updateUserColumnPreferences = createAsyncThunk('userColumns/updateUserColumnPreferences', async (data) => {
  const response = await updateColumnPreferences(data);
  return response.data;
});

const userColumnPreferenceSlice = createSlice({
  name: 'userColumns',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateUserColumnPreferences.fulfilled, (state, action) => {
      const { mail_scan, tracking_info } = action.payload;
      state.mailScan = { table_columns: mail_scan.table_columns };
      state.trackingInfo = { table_columns: tracking_info.table_columns };
    });
  },
});

export default userColumnPreferenceSlice.reducer;
