// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = (user) => {
  let config = [
    {
      title: 'Dashboard',
      path: '/dashboard/app',
      icon: icon('dashboard'),
    },
    {
      title: 'Tracking ',
      path: '/dashboard/tracking',
      icon: icon('tracking'),
    },
    {
      title: 'Mail\xa0Scans',
      path: '/dashboard/mail_scans',
      icon: icon('mail_scans'),
    },
    {
      title: 'Reports',
      path: '/#report_menu',
      icon: icon('reports'),
      children: [
        {
          title: 'Preshipment Alert',
          path: '/dashboard/reports/preshipment',
        },
        {
          title: 'In\xa0Transit Alert',
          path: '/dashboard/reports/intransit',
        },
        {
          title: 'Average Time InTransit',
          path: '/dashboard/reports/average_intransit_time',
        },
      ],
    },
    {
      title: 'Replacement\xa0Orders',
      path: '/dashboard/replace-order',
      icon: icon('replace'),
    },
  ];

  if (user?.role?.role_name == 'Administrators') {
    const configItem = { title: 'Configurations', path: '/dashboard/config', icon: icon('settings') };
    config.push(configItem);
  }
  return config;
};

export default navConfig;
