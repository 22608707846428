import axios from 'axios';
import { deleteToken } from 'src/utils/extractUserToken';

export default async function getData(url, parmas = null, token = null) {
  const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Apikey: apiKey,
    },
    params: parmas,
  };

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // const response = await axios.get(url, config);

  // if (response?.data?.statusCode == "401"){
  //   deleteToken()
  // }
  // return response.data;
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    if (error.response?.data?.statusCode == '401') {
      deleteToken();
    }
    throw error; // Rethrow the error to allow the calling code to handle it as well, if needed.
  }
}
