import { Helmet } from 'react-helmet-async';

// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
// import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    margin: 'auto',
    justifyContent: 'center',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 600,
  margin: theme.spacing(5, -2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 5),
  backgroundColor: theme.palette.secondary.lighter,
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  // const { pathname } = useLocation();

  return (
    <>
      <Helmet>
        <title> Login | Shipping Insights UI </title>
      </Helmet>

      <StyledRoot>
        <Container
          maxWidth="sm"
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <Logo
            isLight={false}
            sx={{
              position: 'fixed',
              top: { xs: 16, sm: 24, md: 40 },
              left: { xs: 16, sm: 24, md: 40 },
            }}
          />
          <StyledSection
            sx={{
              width: {xl:800, lg: 681,md: 681, sm:800 },
            }}
          >
            <img src="/assets/images/login-img.png" alt="login" style={{}} />
          </StyledSection>
        </Container>

        <Container maxWidth="sm">
          <StyledContent
            sx={{
              marginTop: { sm: 30, md: 'auto' },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: '#004152',
                textAlign: 'center',
                paddingBottom: { sm: 2, lg: 8 },
                fontSize: { sm: 60, md: 64 },
              }}
            >
              Welcome!
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
