/* eslint-disable camelcase */
/* eslint-disable no-loss-of-precision */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
// @mui
import {
  Container,
  Modal,
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { unixToDate } from 'src/utils/core';
import SmartTable from '../components/smart-table/SmartTable';
import { useAppSelector, useAppDispatch } from 'src/hooks/hooks';
import { logoutUser } from 'src/features/user/userState';
import { getUserInfo } from 'src/features/user/userState';
import { incrementAwaitingApiCounter, decrementAwaitingApiCounter } from 'src/features/ui/uiState';
import getReplacementOrdersList from 'src/features/replacementOrder/replacementApi';
import { sendReplacementOrdersList, getStatusUpdatedOrdersList } from 'src/features/replacementOrder/replacementApi';
import DateRangePicker from 'src/components/date-picker/DateRangePicker';
import MultiSelectInput from 'src/components/select/MulltiSelect';

const popUpModalStyle = {
  // margin: { lg: '99px 157px 278px 157px' },
  position: 'absolute',
  top: { lg: '50%', md: '50%', sm: '49%' },
  left: { lg: '49%', md: '52%', sm: '50%' },
  transform: 'translate(-50%, -50%)',
  width: { lg: '50%', md: '60%', sm: '85%' },
  height: 'max-content',
  borderRadius: '6px',
  background: '#FFF',
  padding: '2rem',
  overflow: 'auto',
  overflow: 'auto-y',
  overflowX: 'hidden',
  outline: 'none',
};

function TabMenu({ activeStatus, onStatusChange, statusList }) {
  const StyledButton = styled(Button)(({ theme }) => ({
    borderRadius: 0,
    color: theme.palette.text.light,
    fontWeight: 500,
  }));

  const finalStatusList = [...new Set(statusList)];
  const buttonList = [];
  finalStatusList.forEach((item) =>
    buttonList.push(
      <StyledButton
        key={item}
        name={item}
        className={activeStatus === item && 'active'}
        onClick={() => onStatusChange(item)}
        sx={{
          '&.active': {
            borderBottom: '3px solid',
            borderColor: 'primary.dark',
            color: 'primary.dark',
            lineHeight: '24px',
          },
          fontSize: { lg: 16, md: 16, sm: 14 },
          fontFamily: 'Whitney',
        }}
      >
        {item}
      </StyledButton>
    )
  );
  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{
        borderBottom: 0,
        borderStyle: 'line',
        borderColor: 'secondary.gray',
        marginBottom: 2,
      }}
    >
      {buttonList}
    </Stack>
  );
}

function ReplacementOrderPage() {
  const [mainTrackingList, setMainTrackingList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showLoader, setShowLoader] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(dayjs().format('MM/DD/YYYY'));
  const userInfo = useAppSelector(getUserInfo);
  const [advancedFormData, setAdvacnedFromData] = useState({ report_type: 'label_created_date' });
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const dispatch = useAppDispatch();
  const columnsToRemove = ['scanned_with_estimated_delivery_date', 'scanned_without_estimated_delivery_date'];
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [replacementOrderList, setReplacementOrderList] = useState([]);
  const [rejectReason, setRejectReason] = useState('');
  const [activeTab, setActiveTab] = useState('Replacement Orders');
  const [showToast, setShowToast] = useState(false);
  const [toastContent, setTostContent] = useState({
    message: '',
    severity: 'error',
  });
  const [orderType, setOrderType] = useState('All');

  const dateTypeList = [
    {
      label: 'Label Created Date',
      value: 'label_created_date',
    },
    {
      label: 'Order Created Date',
      value: 'order_created_date',
    },
    {
      label: 'Tracking Date',
      value: 'db_created_date',
    },
  ];

  // get Tracking number list
  const fetchReplacementOrdersList = (params, appendRow = false) => {
    try {
      dispatch(incrementAwaitingApiCounter());
      setShowLoader(true);
      let res = null;
      if (activeTab == 'Replacement Orders') {
        delete params['status'];
        delete params['dateRange'];
        res = getReplacementOrdersList(params, userInfo?.accessToken);
      } else {
        delete params['skip'];
        delete params['limit'];
        res = getStatusUpdatedOrdersList(params, userInfo?.accessToken);
      }
      res
        .then((result) => {
          if (mainTrackingList.length > 0 && appendRow) {
            setMainTrackingList([...new Set(mainTrackingList), ...result.data]);
          } else {
            setMainTrackingList(result.data);
          }
          setTotalItemCount(result.total_count);
          dispatch(decrementAwaitingApiCounter());
          setShowLoader(false);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status == '401') {
            dispatch(logoutUser());
          }
          setShowLoader(false);
          dispatch(decrementAwaitingApiCounter());
        });
    } catch (err) {
      console.log(err);
      dispatch(decrementAwaitingApiCounter());
    }
  };

  useEffect(() => {
    fetchReplacementOrdersList(
      {
        status: activeTab,
        dateRange: `${startDate}-${endDate}`,
        skip: page * rowsPerPage,
        limit: rowsPerPage,
        token: userInfo?.accessToken,
      },
      false
    );
  }, [activeTab]);

  const estimatedTimeFormatted = mainTrackingList?.map((item) =>
    item.created_at
      ? {
          ...item,
          created_date: `${unixToDate(item.created_at)}`,
          label_created_date: `${unixToDate(item.label_created_date)}`,
        }
      : item
  );

  // formate key names
  const originalRows1 = estimatedTimeFormatted?.map(
    ({
      created_date: created_date,
      carrier: carrier,
      label_created_date: label_created_date,
      order_increment_id: order_id,
      tracking_number: tracking_number,
      // status,
      replace_order_id: replacement_order_id,
      reject_reason: reject_reason,
      replacement_order_type: order_type,
      error_message: error_reason,
    }) => {
      if (activeTab == 'Approved') {
        return {
          created_date,
          carrier,
          label_created_date,
          order_id,
          tracking_number,
          // status,
          replacement_order_id,
          order_type,
        };
      }
      if (activeTab == 'Rejected') {
        return {
          created_date,
          carrier,
          label_created_date,
          order_id,
          tracking_number,
          reject_reason,
          // status,
        };
      }
      if (activeTab == 'Retry') {
        return {
          created_date,
          carrier,
          label_created_date,
          order_id,
          tracking_number,
          order_type,
          error_reason,
          // status,
        };
      }
      return {
        created_date,
        carrier,
        label_created_date,
        order_id,
        tracking_number,
        // status,
      };
    }
  );

  const defaultColumn = 'order_id';

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= page || mainTrackingList.length > rowsPerPage * (page + 1)) {
      return;
    }
    fetchReplacementOrdersList(
      {
        status: activeTab,
        dateRange: `${startDate}-${endDate}`,
        skip: newPage * rowsPerPage,
        limit: rowsPerPage,
        token: userInfo?.accessToken,
      },
      true
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
    fetchReplacementOrdersList(
      {
        skip: 0,
        limit: parseInt(event.target.value, 10),
        token: userInfo?.accessToken,
        status: activeTab,
        dateRange: `${startDate}-${endDate}`,
      },
      false
    );
  };

  const onExpandhandler = () => {
    console.log('');
  };

  const onReplaceHandler = (status, data) => {
    try {
      let temp = [];
      if (typeof data != 'number') {
        temp = [...data];
      } else {
        temp.push(data);
      }
      setReplacementOrderList(temp);
      if (status == 'approve') {
        let payload = {};
        payload['order_increment_ids'] = temp;
        payload['status'] = 'approved';
        let res = sendReplacementOrdersList(payload, userInfo?.accessToken);
        res
          .then((result) => {
            setShowToast(true);
            setTostContent({
              message: `${temp.toString()} added to queue`,
              severity: 'success',
            });
          })
          .catch((error) => {
            console.log('error', error.message);
            setShowToast(true);
            setTostContent({
              message: error.message,
              severity: 'error',
            });
          });
        mainTrackingList.reduceRight(function (acc, obj, idx) {
          if (temp.indexOf(obj.order_increment_id) > -1) mainTrackingList.splice(idx, 1);
        }, 0);
      } else {
        setShowRejectModal(status == 'reject');
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const onRejectHandler = () => {
    let payload = {};
    payload['order_increment_ids'] = replacementOrderList;
    payload['status'] = 'rejected';
    payload['reject_reason'] = rejectReason;
    let res = sendReplacementOrdersList(payload, userInfo?.accessToken);
    res
      .then((result) => {
        setShowToast(true);
        setTostContent({
          message: `${replacementOrderList.toString()} added to queue`,
          severity: 'success',
        });
      })
      .catch((error) => {
        console.log('error', error);
        setShowToast(true);
        setTostContent({
          message: error.message,
          severity: 'error',
        });
      });
    mainTrackingList.reduceRight(function (acc, obj, idx) {
      if (replacementOrderList.indexOf(obj.order_increment_id) > -1) mainTrackingList.splice(idx, 1);
    }, 0);
    setShowRejectModal(false);
  };

  const StatusList = ['Replacement Orders', 'Approved', 'Rejected', 'Retry'];

  const getDateRange = (dates) => {
    if (dates != null && dates.length > 0) {
      const formattedStartDate = dayjs(dates[0]).format('MM/DD/YYYY');
      const formattedEndDate = dayjs(dates[1]).format('MM/DD/YYYY');
      const date_range = `${formattedStartDate}-${formattedEndDate}`;
      fetchReplacementOrdersList(
        {
          status: activeTab,
          dateRange: date_range,
          skip: page * rowsPerPage,
          limit: rowsPerPage,
          token: userInfo?.accessToken,
        },
        false
      );
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    }
  };

  const typeList = [
    { label: 'All', value: 'All' },
    { label: 'Manual', value: 'Manual' },
    { label: 'Automatic', value: 'Automatic' },
  ];

  const onOrderTypeChangeHandler = (orderType) => {
    if (orderType !== 'All') {
      fetchReplacementOrdersList(
        {
          status: activeTab,
          type: orderType,
          dateRange: `${startDate}-${endDate}`,
          skip: page * rowsPerPage,
          limit: rowsPerPage,
          token: userInfo?.accessToken,
        },
        false
      );
    } else {
      fetchReplacementOrdersList(
        {
          status: activeTab,
          dateRange: `${startDate}-${endDate}`,
          skip: page * rowsPerPage,
          limit: rowsPerPage,
          token: userInfo?.accessToken,
        },
        false
      );
    }

    setOrderType(orderType);
  };

  return (
    <>
      <Helmet>
        <title> Replacement Orders | Shipping Insights </title>
      </Helmet>
      <Modal open={showRejectModal} onClose={() => setShowRejectModal(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'left',
            justifyContent: 'left',
            ...popUpModalStyle,
          }}
        >
          <Typography variant="h6" color="common.black">
            Cancel Reason:
          </Typography>
          <TextField
            id="outlined-basic"
            fullWidth
            multiline
            sx={{
              borderRadius: '6px',
              bgcolor: '#f2f2f2',
              '& .Mui-focused': {
                // bgcolor: 'red',
                outline: 'none',
                borderRadius: '6px',
                '& :focus': {
                  outline: 'none',
                },
              },
              '& textarea': { color: '#000', borderRadius: '6px' },
              '& :focus': {
                outline: '1px solid #fff',
              },
            }}
            rows={4}
            variant="outlined"
            onChange={(e) => setRejectReason(e.target.value)}
          />
          <div style={{ display: 'flex', gap: '5px', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              variant="contained"
              onClick={() => onRejectHandler()}
              sx={{
                display: 'flex',
                width: { lg: '81px', md: '81px', sm: '63px' },
                height: { lg: '30px', sm: '30px' },
                padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 'none',
                flexShrink: 0,
                bgcolor: 'primary.light',
                textAlign: 'center',
                fontFamily: 'Whitney',
                fontSize: { lg: '14px', sm: '14px' },
                fontWeight: 500,
              }}
            >
              Submit
            </Button>
            <Button
              type="button"
              variant="contained"
              onClick={() => setShowRejectModal(false)}
              sx={{
                display: 'flex',
                width: { lg: '81px', md: '81px', sm: '63px' },
                height: { lg: '30px', sm: '30px' },
                padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 'none',
                flexShrink: 0,
                color: 'primary.main',
                bgcolor: 'primary.lightbg',
                textAlign: 'center',
                fontFamily: 'Whitney',
                fontSize: { lg: '14px', sm: '14px' },
                fontWeight: 500,
                ':hover': {
                  color: 'common.white',
                  backgroundColor: 'primary.light',
                },
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={showToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() => setShowToast(false)}
      >
        <Alert
          onClose={() => setShowToast(false)}
          severity={toastContent.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {toastContent.message}
        </Alert>
      </Snackbar>

      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between">
          <TabMenu
            activeStatus={activeTab}
            onStatusChange={(item) => {
              setActiveTab(item);
              setStartDate(dayjs().subtract(7, 'day').format('MM/DD/YYYY'));
              setEndDate(dayjs().format('MM/DD/YYYY'));
              setOrderType('All');
            }}
            statusList={StatusList}
          />
          <Box display="flex" flexDirection="row" alignItems="flex-start" gap={2}>
            {activeTab == 'Approved' && (
              <MultiSelectInput
                name="orderType"
                listItem={typeList}
                defaultValue={orderType}
                onChangeAdvancedForm={(e) => onOrderTypeChangeHandler(e.target.value)}
                sx={{
                  '&  .MuiFormControl-root > .MuiInputBase-root': { height: { lg: 41, md: 41, sm: 35 } },
                }}
                multiple={false}
              />
            )}

            {['Approved', 'Rejected', 'Retry'].includes(activeTab) && (
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                sx={{ marginBottom: 2 }}
                onDateChange={getDateRange}
              />
            )}
          </Box>
        </Stack>
        <SmartTable
          isSearch={false}
          isAdvancedFilter={false}
          isStatusMenu={false}
          isRowSelectable={!['Approved', 'Rejected', 'Retry'].includes(activeTab)}
          refreshAction={false}
          showToolbar={false}
          isGridSelection={true}
          originalRows={typeof originalRows1 !== 'undefined' ? originalRows1 : [{}]}
          defaultColumn={defaultColumn}
          totelItemCount={totalItemsCount}
          onExpandhandler={onExpandhandler}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          showLoader={showLoader}
          advancedFormData={advancedFormData}
          dateTypeList={dateTypeList}
          userSelectedColumns={userSelectedColumns}
          columnsToRemove={columnsToRemove}
          replaceAction={!['Approved', 'Rejected', 'Retry'].includes(activeTab)}
          onReplaceHandler={onReplaceHandler}
          rowHover={false}
          style={{ cursor: 'auto' }}
        />
      </Container>
    </>
  );
}

export default ReplacementOrderPage;
