import { combineReducers } from 'redux';
import userDetailsReducer from './userDetailsReducer';
import userReducer from 'src/features/user/userState';
import uiReducer from 'src/features/ui/uiState';
import trackingReducer from '../features/tracking/trakingState';
import scanDetail from '../features/scan/scanState';
import columnPreferenceState from '../features/tableActions/userColPreferanceState';
import configReducer from 'src/features/config/configState';
import replacementOrderReducer from 'src/features/replacementOrder/replacementState';
// import configDetailReducer from '../features/config/configState';

export default combineReducers({
  // user: userDetailsReducer,
  ui: uiReducer,
  user: userReducer,
  trackInfo: trackingReducer,
  scan: scanDetail,
  userColumns: columnPreferenceState,
  config: configReducer,
  replacementOrder: replacementOrderReducer,
  // configDetails: configDetailReducer,
});
