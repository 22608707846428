/* eslint-disable */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, MenuItem, Avatar, IconButton, Popover, Typography } from '@mui/material';
// mocks_
import { ActionCreators } from '../../../reducer/userDetailsReducer';

import Iconify from '../../../components/iconify';
import account from '../../../_mock/account';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { getUserInfo, logoutUser } from 'src/features/user/userState';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo)

  useEffect(() => {
    setUserName(userInfo?.user?.user_username);
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    dispatch(logoutUser())
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.primary.light),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 200,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box
          sx={{
            my: 1.5,
            px: 2.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Avatar />
          <Typography variant="body2" sx={{ color: 'common.black', px: 2 }} noWrap>
            {userName}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'line' }} />
        <Box
          sx={{
            my: 1.5,
            px: 2.5,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Iconify
            icon="mdi:logout"
            sx={{
              color: 'text.disabled',
              width: 30,
              height: 26,
            }}
          />
          <MenuItem onClick={handleLogout} sx={{ m: 1, color: 'common.black' }}>
            Logout
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
}
