import React, { useState } from 'react';
import { Grid, FormLabel, Select, MenuItem, FormControl, Checkbox, Box } from '@mui/material';
import PropTypes from 'prop-types';
import Button from 'src/theme/overrides/Button';

function MultiSelectInput({
  label,
  name,
  advancedFormData,
  onChangeAdvancedForm,
  listItem,
  sx,
  defaultValue,
  multiple = true,
}) {
  const [selectedValues, setSelectedValues] = useState(defaultValue || []);

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedValues(value);
    onChangeAdvancedForm({ target: { name, value } });
  };

  return (
    <Grid item lg={3} sm={4} sx={{ ...sx }}>
      {label && (
        <FormLabel
          htmlFor={name}
          variant="subtitle2"
          sx={{ color: 'secondary.light', marginBottom: 1, fontSize: { lg: 14, sm: 12 }, fontWeight: 600 }}
        >
          {label}
        </FormLabel>
      )}
      <FormControl
        fullWidth
        sx={{
          borderRadius: 2,
          border: '4 solid #E0E0E0',
          width: '100%',
          bgcolor: '#FFF',
          color: 'primary.main',
          '& input': { color: '#000' },
        }}
      >
        <Select
          labelId={`${name}-simple-select-label`}
          id={`${name}-simple-select`}
          name={name}
          value={selectedValues}
          onChange={handleSelectChange}
          multiple={multiple}
          sx={{ color: '#000' }}
          renderValue={(selected) => (multiple ? selected?.join(', ') : selected)}
        >
          {listItem?.map((item) => (
            <MenuItem key={item.value} sx={{ color: '#000' }} value={item.value}>
              {multiple && <Checkbox checked={selectedValues.includes(item.value)} />}
              {item.label}
            </MenuItem>
          ))}
          {/* <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
            alignItems: 'center',
        }}
      >
            <Button
              type="button"
              variant="contained"
              onClick={() => handleColumnSubmit(tempSelectedColumns)}
              sx={{
                display: 'flex',
                width: { lg: "98px", md: "", sm: "63px" },
                height: { lg: '40px', sm: "33px" },
                padding: '0.5rem 1.75rem 0.625rem 1.813rem',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: 'none',
                flexShrink: 0,
                bgcolor: 'primary.light',
                textAlign: 'center',
                fontFamily: 'Whitney',
                fontSize: { lg: '16px !important', sm: "14px" },
                fontWeight: 500,
              }}
            >
              Apply
            </Button>
      </Box> */}
        </Select>
      </FormControl>
    </Grid>
  );
}

MultiSelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  advancedFormData: PropTypes.object,
  onChangeAdvancedForm: PropTypes.func,
  listItem: PropTypes.array,
  sx: PropTypes.object,
  defaultValue: PropTypes.array,
};

export default MultiSelectInput;
