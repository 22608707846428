import axios from 'axios';
import { deleteToken } from 'src/utils/extractUserToken';

export default async function postData(url, data, token = null) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Apikey: process.env.REACT_APP_BACKEND_API_KEY,
      'Access-Control-Allow-Origin': '*',
    },
  };

  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  } 
  let result = {};
  try {
    const response = await axios.post(url, data, config);
    result = response?.data;
  } catch (e) {
    if (e?.response?.status == "401"){
      deleteToken()
    }
    throw new Error(e?.response?.data?.statusMessage || 'Something went wrong! ')
  }
  return result;
}
