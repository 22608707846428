
var local_token = null;

export const setToken = (token) => {
  localStorage.setItem('user_token', token);
  local_token = token;
};

export const getToken = () => {
  if (!local_token) {
    local_token = localStorage.getItem('user_token');
  }
  return local_token || null;
};

export const deleteToken = () => {
  localStorage.removeItem('user_token');
  local_token = null;
};
