import {
    Grid,FormLabel, TextField,
  } from '@mui/material';


function InputForm ( {label, name, advancedFormData, onChangeAdvancedForm }) {
    return(
        <Grid item lg={3} sm={4}>
            <FormLabel htmlFor="input1" variant="subtitle2" sx={{ color: 'secondary.light', marginBottom:1, fontSize:{lg:14, sm:12}, fontWeight:600 }}>
              {label}
            </FormLabel>
            <TextField 
            id="input1" 
            value={advancedFormData[name] ? advancedFormData[name]: null}
            name={name}
            onChange={(e)=>onChangeAdvancedForm(e)}
            fullWidth sx={{ borderRadius: 1.7, border: '4 solid #E0E0E0', bgcolor: '#FFF', marginTop:{lg:1, sm:.3}, color:'primary.main', '& input': {height:{lg:16, md:8, sm:2}, width:{md:207}, color:"#000"}}} 
            />
      </Grid>
    )
    
    }

  export default InputForm;