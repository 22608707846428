import Cookies from 'js-cookie';
import { AES, enc } from 'crypto-js';



export function retrieveCookies(setValue) {
  const secretVariableName = 'd4521t'; 
  const storedCredentials = Cookies.get(secretVariableName);
  if (storedCredentials) {
    const decryptedCredentials = AES.decrypt(storedCredentials, 'secret-key').toString(enc.Utf8);
    const { username, password } = JSON.parse(decryptedCredentials);
    setValue('username', username);
    setValue('password', password);
  }
}
export const storeCredentialsInCookies = (username, password) => {
    const secretVariableName = 'd4521t'; 
    const credentials = { username, password };
    const encryptedCredentials = AES.encrypt(JSON.stringify(credentials), 'secret-key').toString();
    Cookies.set(secretVariableName, encryptedCredentials);
  };


