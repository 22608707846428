import { DatePicker } from 'antd';
import {
  Grid, FormLabel, Box, Stack,
} from '@mui/material';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import moment from 'moment';
import Iconify from '../iconify/Iconify';

function DatePickerInput({
  name, label, onChangeAdvancedForm, advancedFormData,futureDateEnable
}) {
  return (
    <Grid item lg={3} sm={4}>
      <FormLabel
        htmlFor="input1"
        labelPlacement="top"
        variant="formLabel"
        sx={{
          color: 'secondary.light', marginBottom: 2, fontSize: { lg: 14, sm: 12 }, fontWeight: 600,
        }}
      >
        {label}
      </FormLabel>
      <Stack direction="row" sx={{ marginTop: { lg: 1, sm: 0.3 }, height: { lg: 48, md: 41, sm: 35 } }}>
        <DatePicker
          format="MM-DD-YYYY"
          name={name}
          allowEmpty
          value={advancedFormData[name] && advancedFormData[name] !== 'Invalid Date' ? dayjs(advancedFormData[name]) : false}
          controlHeight={16}
          disabledDate={(current) => current && (current.isBefore('2023-06-19') || (!futureDateEnable && current.isAfter(dayjs())))}
          style={{
            width: '100%',
            border: '10 solid #E0E0E0',
            bgcolor: '#FFF',
            color: 'primary.main',
          }}
          onChange={(date) => onChangeAdvancedForm(name, dayjs(date).format('MM/DD/YYYY'))}
          suffixIcon={<CalenderIcon/>}
        />
      </Stack>
    </Grid>
  );
}
export const CalenderIcon = () => {
  return(
    <>
    <Box
          sx={{
            backgroundColor: 'primary.light',
            color: 'common.white',
            ml: -0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          marginRight: "-19px",
          height: { sm: 33, lg: 47 },
          width: { sm: 32, lg: 48 },
            zIndex: 1,
            borderTopRightRadius: { lg: 9, sm: 6 },
            borderBottomRightRadius: { lg: 9, sm: 6 },
          }}
        >
          <Iconify sx={{ width: 40, height: 20 }} icon="mdi:calendar-blank" />
        </Box>
    </>
  )
        }

DatePickerInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  advancedFormData: PropTypes.object,
  onChangeAdvancedForm: PropTypes.func,
  futureDateEnable: PropTypes.bool,
};

export default DatePickerInput;
