import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getTrackingList from './trackingApi';

const initialState = {
  trackList: {
    total_count: 0,
    current_page: 0,
    items: [{
      tracking_number: '',
      carrier: '',
      order_id: [],
      first_name: '',
      status: '',
    },
    ],
  },
};

export const getTracking = createAsyncThunk(
  'tracking/list',
  async (data) => {
    const response = await getTrackingList(data);
    return response;
  },
);

const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    resetTrackState: (state) => initialState,
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTracking.pending, (state, action) => {
        state.trackList = action.payload;
      })
      .addCase(getTracking.fulfilled, (state, action) => {
        state.trackList = action.payload.data;
      })
      .addCase(getTracking.rejected, (state, action) => {
        state.trackList = action.payload;
      });
  },
});

export const {
  resetTrackState,
} = trackingSlice.actions;

export const getTrackingInfo = (state) => state.tracking;

export default trackingSlice.reducer;
