import dayjs from 'dayjs';
import moment from 'moment-timezone';

const validateEmail = (email) =>
  email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export default validateEmail;

export const titleCase = (data) => {
  try {
    const formattedData = data?.split('_').join(' ');
    const str = formattedData?.split(' ');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < str?.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  } catch (err) {
    console.log(err);
    return data;
  }
};

export const removeTitleCase = (data) => {
  const formattedData = data.split(' ').join('_');
  const str = formattedData.toLowerCase().split(' ');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toLowerCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const removeSessionData = (sessionKey) => {
  sessionStorage.removeItem(sessionKey);
};

export const setSessionData = (sessionKey, value) => {
  sessionStorage.setItem(sessionKey, JSON.stringify(value));
};

export const getSessionData = (sessionKey) => {
  const sessionData = JSON.parse(sessionStorage.getItem(sessionKey));
  return sessionData;
};

export const unixToDate = (unixdata) => {
  if (typeof unixdata === 'number' && unixdata) {
    const date = moment.unix(unixdata).tz('America/Los_Angeles').format('MMM DD, YYYY');
    return date;
  } else {
    return '--';
  }
};

export const unixToTime = (unixdata) => {
  const time = moment.unix(unixdata).tz('America/Los_Angeles').format('HH:mm');
  return time;
};

export const dateDiff = (startDate, endDate) => {
  if (endDate === '--' || endDate === null || endDate === undefined) {
    endDate = moment().tz('America/Los_Angeles').format('MMM DD, YYYY');
  }
  const start = moment(startDate);
  const end = moment(endDate);

  const dateDifference = end.diff(start, 'days');
  return dateDifference;
};

export const dateToUnix = (date) => {
  const momentDateTime = moment.tz(date, 'MM/DD/YYYY', 'America/Los_Angeles');
  const epoch = momentDateTime.unix();
  return epoch;
};

export const datePstToUtc = (date) => {
  return moment.utc(date).format('MM/DD/YYYY');
};

export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      delete obj[propName];
    }
  }
  return obj;
};

export const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};
