import getData from 'src/dataFetcher/getDataFetcher';
import postData from 'src/dataFetcher/postDataFetcher';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default async function getReplacementOrdersList(parmas, token) {
  delete parmas['token'];
  const result = await getData(`${backendUrl}get-manual-replacement-orders`, parmas, token);
  return result;
}

export async function sendReplacementOrdersList(data, token) {
  const result = await postData(`${backendUrl}replacement/createManualReplacement`, data, token);
  return result;
}

export async function getStatusUpdatedOrdersList(parmas, token) {
  delete parmas['token'];
  const result = await getData(`${backendUrl}replacement/replacementLists`, parmas, token);
  return result;
}
