/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { getUserError, login } from 'src/features/user/userState';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { retrieveCookies, storeCredentialsInCookies } from 'src/utils/cookiesTracker';
// ----------------------------------------------------------------------
const styleLoginForm = {
  color: 'black',
  width: { lg: 560, md: 517 },
  height: { lg: 607, md: 656 },
  // marginRight: { md: 5 },
};
const stylePassModal = {
  position: 'absolute',
  top: { lg: '46%', md: '42.5%', sm: '45%' },
  left: { lg: '47.5%', md: '52%', sm: '50%' },
  transform: 'translate(-50%, -50%)',
  width: 597,
  height: 351,
  borderRadius: '15px',
  background: '#FAFAFA',
  padding: '40px',
  overflow: 'hidden',
  outline: 'none',
};

export default function LoginForm() {
  const StyledContent = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    color: theme.palette.common.black,
    // padding: theme.spacing(12, 0),
  }));

  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const magentoURL = process.env.REACT_APP_MAGENTO_URL;

  const dispatch = useAppDispatch();
  const loginError = useAppSelector(getUserError);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const rememberMe = watch('remember', false);

  const StyledTextField = styled(TextField)(({ theme }) => ({
    '& > div > input': {
      color: theme.palette.primary.main,
    },
  }));
  useEffect(() => {
    retrieveCookies(setValue);
  }, [setValue]);

  const onSubmit = (data) => {
    if (Object.keys(errors).length === 0) {
      dispatch(login(data)).then((action) => {
        const { username, password } = action.meta.arg;
        const rememberMe = data.remember;
        setDisplayError(true);
        if (rememberMe) {
          storeCredentialsInCookies(username, password);
        }
      });
    }
  };

  const redirectToMagento = () => {
    window.open(magentoURL, '_blank').focus();
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} style={styleLoginForm}>
        <TextField
          sx={{
            '& > div > input': { color: 'common.black', fontFamily: 'Whitney', fontWeight: 500},
            width: { lg: 515, md: 446, sm: 446 },
           
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'secondary.light',
            },
            "& .MuiInputLabel-formControl": {
              color: "#00000099"
            },
            "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
              color: "#00000099"
            }
          }}
          name="username"
          label="User name"
          {...register('username', { required: 'User name is required.' })}
          error={errors.username || errors.invalidUser}
          helperText={errors.username ? errors.username.message : errors.invalidUser?.message}
          style={{ color: 'black' }}
          onFocus={() => setDisplayError(false)}
         
        />

        <TextField
          name="password"
          sx={{
            '& > div > input': { color: 'common.black', fontFamily: 'Whitney', fontWeight: 500 },
            width: { lg: 515, md: 446, sm: 446 },
            
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'secondary.light',
            },
            "& .MuiInputLabel-formControl":{
              color:"#00000099"
            },
            "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
              color:"#00000099"
            }
          }}
          label="Password"
          type={showPassword ? 'text' : 'password'}
          {...register('password', { required: 'Password is required.' })}
          error={errors.password}
          helperText={errors.password?.message}
          style={{ color: 'black' }}
          onFocus={() => setDisplayError(false)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox {...register('remember')} sx={{ color:'#000000'}}/>}
          label={
            <Typography
              varient="subtitle1"
              sx={{ color: 'common.black', fontSize: '18px !important', fontWeight: 500, fontFamily: 'Whitney' 
             }}
            >
              Remember Me
            </Typography>
          }
          sx={{ color: 'common.black', fontSize: '18px !important', fontWeight: 500,
        '& .Mui-checked':{
          bgColor:'black'
        } 
      }}
        />
        <Box sx={{ borderBottom: 1, borderColor: 'primary.main', alignItem: 'center', height: 22.8 }}>
          <Link variant="h6" underline="none" sx={{ fontWeight: 500 }} onClick={() => setOpenModal(true)}>
            Forgot password?
          </Link>
        </Box>
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{
          fontFamily: 'Whitney',
          fontSize: '18px !important',
          fontWeight: 700,
          width: { lg: 513, sm: 444, md: 444 },
          boxShadow: 'none',
        }}
      >
        Login
      </LoadingButton>
      <StyledContent style={{ alignItems: 'center', marginTop: '20px', color: 'red', minHeight: '25px' }}>
        <Typography variant="p">{loginError?.loginError && displayError && `${loginError.loginErrorMessage}`}</Typography>
      </StyledContent>

      {/* PasswordChange Modal  */}
      <div>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={stylePassModal}>
            <StyledContent>
              <Typography variant="h3" color="primary.main">
                Password Change
              </Typography>

              <Typography variant="subtitle1" sx={{ color: 'palette.common.black', py: 4 }}>
                Authentication is managed in Magento so you will need to reset your password from there.
              </Typography>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  py: 2,
                  boxShadow: 'none',
                  fontSize: '20px',
                  width: 513,
                  fontFamily: 'Whitney Semibold',
                  fontWeight: 500,
                }}
                onClick={redirectToMagento}
              >
                Continue to Magento
              </LoadingButton>

              <Typography sx={{ mx: 'auto', py: 4 }}>
                <Button
                  variant="subtitle2"
                  sx={{ textTransform: 'none', fontSize: '20px', fontWeight: 500, fontFamily: 'Whitney Semibold' }}
                  onClick={() => setOpenModal(false)}
                >
                  Back to sign in
                </Button>
              </Typography>
            </StyledContent>
          </Box>
        </Modal>
      </div>
    </Box>
  );
}
