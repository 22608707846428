import { Grid, FormLabel, Select, MenuItem, FormControl } from '@mui/material';
import PropTypes from 'prop-types';

function SelectInput({ label, name, advancedFormData, onChangeAdvancedForm, listItem, sx, defaultValue }) {
  return (
    <Grid item lg={3} sm={4} sx={{ ...sx }}>
      {label && (
        <FormLabel
          htmlFor="input1"
          variant="subtitle2"
          sx={{ color: 'secondary.light', marginBottom: 1, fontSize: { lg: 14, sm: 12 }, fontWeight: 600 }}
        >
          {label}
        </FormLabel>
      )}
      <FormControl
        fullWidth
        sx={{
          borderRadius: 2,
          border: '4 solid #E0E0E0',
          width: '100%',
          bgcolor: '#FFF',
          color: 'primary.main',
          '& input': { color: '#000' },
        }}
      >
        <Select
          labelId={`${name}-simple-select-label`}
          id={`${name}-simple-select`}
          name={name}
          value={defaultValue || advancedFormData[name]}
          onChange={(e) => onChangeAdvancedForm(e)}
          sx={{ color: '#000' }}
        >
          {defaultValue && (
            <MenuItem key={defaultValue} sx={{ color: '#000' }} value={defaultValue} selected></MenuItem>
          )}
          {listItem?.map((item) => (
            <MenuItem key={item.value} sx={{ color: '#000' }} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  advancedFormData: PropTypes.object,
  onChangeAdvancedForm: PropTypes.func,
  listItem: PropTypes.array,
  sx: PropTypes.object,
  defaultValue: PropTypes.string,
};

export default SelectInput;
