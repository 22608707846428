/* eslint-disable camelcase */
/* eslint-disable no-loss-of-precision */
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import dayjs from 'dayjs';
import { setSessionData, unixToDate, unixToTime, datePstToUtc, cleanObject, removeTitleCase } from 'src/utils/core';
// @mui
import { Container, Snackbar, Alert } from '@mui/material';
import getTrackingList, { getTrackingInfo, sendTrackInformationToRefresh } from 'src/features/tracking/trackingApi';
import SmartTable from '../components/smart-table/SmartTable';
import { SocketContext } from '../components/socket-handler/sockethandler';
import { useDispatch, useSelector } from 'react-redux';
import { updateColumnPreferences } from 'src/features/tableActions/userColPreferenceApi';
import { logoutUser, updateUserPreferences } from 'src/features/user/userState';
import { useAppSelector, useAppDispatch } from 'src/hooks/hooks';
import { getUserInfo } from 'src/features/user/userState';
import { titleCase } from 'src/utils/core';
import { deleteToken } from 'src/utils/extractUserToken';
import { incrementAwaitingApiCounter, decrementAwaitingApiCounter } from 'src/features/ui/uiState';

// ----------------------------------------------------------------------

function TrackingPage(callback, deps) {
  const socket = useContext(SocketContext);
  const { trackingNumber, status, first_scan, dashboardStartDate, dashboardEndDate } = useParams();
  const { pathname } = useLocation();
  const trackingInfo = useSelector((state) => state.user.trackingInfo);
  const mailScan = useAppSelector((state) => state.user.mailScan);
  const [expandedRow, setExpandedRow] = useState(null);
  const [mainTrackingList, setMainTrackingList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(dayjs().format('MM/DD/YYYY'));
  const [advancedFormData, setAdvacnedFromData] = useState({});
  const [rawSingleTrackingInfo, setRawSingleTrackingInfo] = useState({});
  const [refreshTrackingInfo, setRefreshTrackingInfo] = useState({});
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [statusAllData, setStatusAllData] = useState('');
  const [statusToShow, setStatusToShow] = useState(statusAllData);
  const [showExportInfo, setShowExportInfo] = useState(false);
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const [statusToFetch, setStatusToFetch] = useState('');
  // const [initDelivered, setInitDelivered] = useState({});
  // const [initInTransit, setInitInTransit] = useState({});
  // const [initPreShipment, setInitPreShipment] = useState({});
  // const [initAlert, setAlert] = useState({});
  const [order, setOrder] = useState('asc');
  const [product, setProduct] = useState('first_name');
  const sortDataString = `sort_data[${product}]`;
  const [noDateRange, setnoDateRange] = useState(false);

  useEffect(() => {
    if (!showAdvancedFilter && (searchKey == '' || searchKey == null)) {
      setPage(0);
      let queryParams = getCurrentPageParams();
      if (pathname === '/dashboard/tracking') {
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            [sortDataString]: order,
          },
          false
        );
      }
    }
  }, [startDate, !advancedFormData, pathname]);

  useEffect(() => {
    const queryParams = getCurrentPageParams();
    if (first_scan) {
      getTrackingNumerList(
        {
          ...queryParams,
          page_size: rowsPerPage,
          current_page: 1,
          first_scan: first_scan,
          [sortDataString]: order,
        },
        false
      );
    } else if (statusToShow) {
      if (
        statusToShow === 'All Orders' ||
        statusToShow.includes(['Delivered', 'In Transit', 'Pre-Shipment', 'Alert'])
      ) {
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            [sortDataString]: order,
          },
          false
        );
      } else {
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: rowsPerPage,
            current_page: 1,
            status: statusToShow,
            [sortDataString]: order,
          },
          false
        );
      }
    }
    setPage(0);
  }, [order, product]);

  const getCurrentPageParams = (isStatusStrict = true, current_page = 1, statusToAdd = null) => {
    let tempFormData = { ...advancedFormData };
    let queryParams = {
      page_size: rowsPerPage,
      current_page,
      [sortDataString]: order,
      ...tempFormData,
    };
    if (noDateRange == true) {
      if (queryParams.hasOwnProperty('date_range')) {
        delete queryParams['date_range'];
      }
    }
    if (startDate != false && endDate != false) {
      if (first_scan) {
        queryParams['date_range'] = `${dashboardStartDate}-${dashboardEndDate}`;
      } else {
        queryParams['date_range'] = `${startDate}-${endDate}`;
      }
    } else {
      if (queryParams.hasOwnProperty('date_range')) {
        delete queryParams['date_range'];
      }
    }
    if (statusToAdd !== 'All Orders' && statusToAdd !== null) {
      queryParams.status = statusToAdd;
    } else if (statusToShow == 'All Orders' && tempFormData.status) {
      queryParams.status = tempFormData.status;
    } else if (statusToShow !== 'All Orders') {
      if (!showAdvancedFilter) {
        queryParams.status = statusToFetch;
      } else if (advancedFormData) {
        queryParams = {
          ...queryParams,
          ...tempFormData,
        };
      } else {
        queryParams.status = statusToFetch;
      }
    }
    return queryParams;
  };

  const sortOrder = (columnName, sortOrder) => {
    setOrder(sortOrder);
    setProduct(columnName);
  };

  const [modalLoader, setModalLoader] = useState(false);

  useEffect(() => {
    if (typeof trackingInfo !== 'undefined' && trackingInfo.length > 0) {
      setUserSelectedColumns(trackingInfo);
    }
  }, [trackingInfo]);

  useEffect(() => {
    if (pathname == '/dashboard/tracking') {
      if (statusToShow && statusToShow !== 'All Orders') {
        setStatusList[[statusToShow]];
      } else {
        setStatusList(['Delivered', 'In Transit', 'Pre-Shipment', 'Alert']);
        setStatusToShow('All Orders');
      }
    }
    if (advancedFormData.hasOwnProperty('status')) {
      const Status = ' ';
      setStatusToShow(Status);
      setStatusList(Status);
      // setStatusToShow(advancedFormData.status);
      setStatusAllData('');
    } else if (status) {
      setStatusToShow(status);
      setStatusAllData('All Orders');
    } else {
      setStatusAllData('All Orders');
    }
  }, [pathname]);

  // const [parms, setParms] = useState({ page_size: rowsPerPage, current_page: page + 1 });
  const navigate = useNavigate();
  const trackingInfoRef = useRef({});

  useEffect(() => {
    if (typeof refreshTrackingInfo.trackNo !== 'undefined') {
      const { trackNo } = refreshTrackingInfo;
      const index = mainTrackingList.findIndex((object) => object.tracking_number === trackNo);
      if (index > -1) {
        const tableList = [...mainTrackingList];
        tableList[index] = refreshTrackingInfo.tableResponseData;
        setMainTrackingList(tableList);
        if (
          rawSingleTrackingInfo.hasOwnProperty('tracking_number') &&
          rawSingleTrackingInfo.tracking_number === trackNo
        ) {
          setRawSingleTrackingInfo({ ...refreshTrackingInfo.trackNoInfo });
        }
      }
    }
  }, [refreshTrackingInfo]);

  useEffect(() => {
    function cbHandleRefreshTrackingDetails(data) {
      console.log('socket data ', data);
      setRefreshTrackingInfo(data);
    }

    socket.on('refresh_tracking_details', cbHandleRefreshTrackingDetails);
    return () => {
      socket.off('refresh_tracking_details', cbHandleRefreshTrackingDetails);
    };
  }, []);

  const exportButtonClicked = () => {
    setShowExportInfo(true);
    let parmas = { export_data: true, export_data_to: userInfo?.user?.user_mail, ...advancedFormData };
    if (startDate !== false && endDate !== false) {
      parmas.date_range = `${startDate}-${endDate}`;
    }
    if (searchKey) {
      parmas.tracking_no = searchKey;
    }
    if (statusToShow !== 'All Orders') {
      parmas.status = statusToShow;
    }
    exportTrackingNumerList(parmas);
  };

  const exportTrackingNumerList = (parms, appendRow = false) => {
    const res = getTrackingList(cleanObject(parms), userInfo?.accessToken);
    res
      .then((result) => {
        console.log(result.statusCode);
      })
      .catch((error) => {
        if (error?.response?.status == '401') {
          dispatch(logoutUser());
        }
        console.log(error);
      });
  };

  // get Tracking number list
  const getTrackingNumerList = (parms, appendRow = false, isOnlyTrackNo = false) => {
    dispatch(incrementAwaitingApiCounter());
    setShowLoader(true);
    let res;
    if (parms.status === 'All Orders') {
      const { status, ...parmsWithoutStatus } = parms;
      res = getTrackingList(cleanObject(parmsWithoutStatus), userInfo?.accessToken);
    } else {
      res = getTrackingList(cleanObject(parms), userInfo?.accessToken);
    }
    res
      .then((result) => {
        const responseData = result.data.items;
        if (mainTrackingList.length > 0 && appendRow && !isOnlyTrackNo) {
          setMainTrackingList([...new Set(mainTrackingList), ...result.data.items]);
        } else {
          setMainTrackingList(result.data.items);
        }
        if (isOnlyTrackNo && responseData.length === 1) {
          setStatusList([responseData[0].status]);
          setStatusToShow(responseData[0].status);
        }
        if (result.data.current_page == 1) {
          setTotalItemCount(result.data.total_count);
        }
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == '401') {
          dispatch(logoutUser());
        }
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      });
  };

  const getSingleTrackNumberInfo = (trackNumber) => {
    setModalLoader(true);
    var parmas = null;
    const res = getTrackingInfo(trackNumber, parmas, userInfo?.accessToken);
    res
      .then((result) => {
        setRawSingleTrackingInfo(result.data);
        setModalLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == '401') {
          dispatch(logoutUser());
        }
        setModalLoader(false);
      });
  };

  const checkTrackingNumber = (arr, trackNumber) => {
    const found = arr.some((el) => el.tracking_number === trackNumber);
    return found;
  };

  //from dashboard track num search
  useEffect(() => {
    if (trackingNumber) {
      if (!checkTrackingNumber(mainTrackingList, trackingNumber)) {
        getTrackingNumerList(
          {
            page_size: rowsPerPage,
            current_page: 1,
            tracking_no: trackingNumber,
          },
          false,
          true
        );
        getSingleTrackNumberInfo(trackingNumber);
      } else {
        getSingleTrackNumberInfo(trackingNumber);
      }
      setExpandedRow(trackingNumber);
      setSearchKey(trackingNumber);
      setStartDate(false);
      setEndDate(false);
      // setStatusList([status]);
      // setStatusToShow(status);
    } else if (first_scan) {
      // Check if first_scan exists then call the api
      setStartDate(dashboardStartDate);
      setEndDate(dashboardEndDate);
      setStatusList([first_scan]);
      setStatusToShow(first_scan);

      getTrackingNumerList({
        page_size: rowsPerPage,
        current_page: 1,
        date_range: `${dashboardStartDate}-${dashboardEndDate}`,
        first_scan,
      });
    } else if (status) {
      setStartDate(dashboardStartDate);
      setEndDate(dashboardEndDate);
      setStatusList([status]);
      setStatusToShow(status);

      getTrackingNumerList({
        page_size: rowsPerPage,
        current_page: 1,
        date_range: `${dashboardStartDate}-${dashboardEndDate}`,
        status,
      });
    } else if (!showAdvancedFilter) {
      if (startDate && endDate) {
        if (first_scan) {
          setStatusList([status]);
          setStatusToShow(status);
          getTrackingNumerList({
            page_size: rowsPerPage,
            current_page: 1,
            date_range: `${startDate}-${endDate}`,
            first_scan,
          });
        } else if (status) {
          setStatusList([status]);
          setStatusToShow(status);
          getTrackingNumerList({
            page_size: rowsPerPage,
            current_page: 1,
            date_range: `${startDate}-${endDate}`,
            status,
          });
        } else {
          // getTrackingNumerList(
          //   {page_size: rowsPerPage, current_page: 1, date_range: `${startDate}-${endDate}`},
          //   false
          // );
        }
      }
    }
  }, [trackingNumber, endDate]);

  // formate key names
  const originalRows1 = mainTrackingList?.map(
    ({
      customer_first_name: first_name,
      customer_last_name: last_name,
      customer_email: email,
      order_id: order_number,
      carrier,
      shipping_date: shipped_on,
      shipping_street: street,
      shipping_city: city,
      shipping_state: state,
      shipping_country: country,
      shipping_postal_code: zip_code,
      shipping_telephone: telephone,
      tracking_number,
      status,
      estimated_delivery_date,
      scanned_status,
    }) => ({
      first_name,
      last_name,
      email,
      order_number,
      carrier,
      shipped_on,
      street,
      state,
      city,
      country,
      zip_code,
      telephone,
      tracking_number,
      status,
      estimated_delivery_date,
      scanned_status,
    })
  );

  // change scan status
  const scanStatus = {
    1: 'Scanned',
    2: 'Un Scanned',
    3: 'Delivered without Scan',
    4: 'Invalid Email',
    5: 'Delivered',
    6: 'Un Scanned Exit met',
    7: 'Returned',
  };

  const dataConverted = [];

  const allFormatetted = originalRows1?.forEach((item) => {
    dataConverted.push({
      ...item,
      scanned_status: scanStatus[item.scanned_status],
      shipped_on: `${unixToDate(item.shipped_on)}`,
      estimated_delivery_date: item.estimated_delivery_date ? `${unixToDate(item.estimated_delivery_date)}` : '--',
      order_number: Object.values(item.order_number).toString(),
    });
  });

  const defaultColumn = 'tracking_number';
  const statusColumn = 'status';

  const carrierSelect = [
    {
      label: 'Select Carrier',
      value: '',
    },
    {
      label: 'UPS',
      value: 'UPS',
    },
    {
      label: 'USPS',
      value: 'USPS',
    },
  ];

  const statusSelect = [
    {
      label: 'Alert',
      value: 'Alert',
    },
    {
      label: 'Available for Agent Pickup',
      value: 'Available for Agent Pickup',
    },
    {
      label: 'Available for Pickup',
      value: 'Available for Pickup',
    },
    {
      label: 'Delivered',
      value: 'Delivered',
    },
    {
      label: 'Delivered to Agent',
      value: 'Delivered to Agent',
    },
    {
      label: 'Delivery Attempt',
      value: 'Delivery Attempt',
    },
    {
      label: 'Forwarded',
      value: 'Forwarded',
    },
    {
      label: 'In Transit',
      value: 'In Transit',
    },
    {
      label: 'Out for Delivery',
      value: 'Out for Delivery',
    },
    {
      label: 'Pre-Shipment',
      value: 'Pre-Shipment',
    },
    {
      label: 'Returned',
      value: 'Returned',
    },
    {
      label: 'Unknown',
      value: 'Unknown',
    },
  ];

  const scannedStatus = [
    {
      label: 'Select Status',
      value: '',
    },
    {
      label: 'Scanned',
      value: 1,
    },
    {
      label: 'Un Scanned',
      value: 2,
    },
    {
      label: 'Delivered Without Scan',
      value: 3,
    },
    {
      label: 'Invalid Email',
      value: 4,
    },
    {
      label: 'Delivered',
      value: 5,
    },
    {
      label: 'Un Scanned Exit Met',
      value: 6,
    },
    {
      label: 'Returned',
      value: 7,
    },
  ];

  const firstScanEmailStatistics = [
    {
      label: 'Select Status',
      value: '',
    },
    {
      label: 'Scanned Successfully',
      value: 'Scanned Successfully',
    },
    {
      label: 'No Scan',
      value: 'No Scan',
    },
    {
      label: 'Scanned with Estimated Delivery Date',
      value: 'Scanned with Estimated Delivery Date',
    },
    {
      label: 'Scanned without Estimated Delivery Date',
      value: 'Scanned without Estimated Delivery Date',
    },
    {
      label: 'Delivered Without Scan',
      value: 'Delivered Without Scan',
    },
  ];

  const getDateRange = (dates) => {
    if (dates == null) {
      setnoDateRange(true);
    }
    if (dates != null && dates.length > 0) {
      const formattedStartDate = dayjs(dates[0]).format('MM/DD/YYYY');
      const formattedEndDate = dayjs(dates[1]).format('MM/DD/YYYY');
      const date_range = `${formattedStartDate}-${formattedEndDate}`;
      setSearchKey(null);
      if (!showAdvancedFilter) {
        if (first_scan) {
          getTrackingNumerList(
            {
              page_size: rowsPerPage,
              current_page: 1,
              date_range: date_range,
              first_scan: first_scan,
            },
            false
          );
          setStatusToShow(first_scan);
          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
        } else if (status) {
          getTrackingNumerList(
            {
              page_size: rowsPerPage,
              current_page: 1,
              date_range: date_range,
              status: status,
            },
            false
          );
          setStatusToShow(status);
          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
          setPage(0);
        } else {
          let queryParams = getCurrentPageParams();
          setStatusToShow(statusToShow);
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: rowsPerPage,
              current_page: 1,
              date_range: date_range,
            },
            false
          );

          setStartDate(formattedStartDate);
          setEndDate(formattedEndDate);
          setPage(0);
        }
      } else {
        setAdvacnedFromData({ ...advancedFormData, date_range: date_range });
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      }
      if (pathname === '/dashboard/tracking') {
        setStatusToShow(statusToShow);
        setStatusList(['Delivered', 'In Transit', 'Pre-Shipment', 'Alert']);
      }
    } else {
      setStartDate(false);
      setEndDate(false);
      if (!showAdvancedFilter) {
        setStatusToShow('All Orders');
        if (statusToShow === 'All Orders') {
          if (noDateRange == true) {
            setStatusToShow('All Orders');
            setnoDateRange(false);
          }
        }
      } else {
        if (advancedFormData.hasOwnProperty('date_range')) {
          delete advancedFormData['date_range'];
          setAdvacnedFromData({ ...advancedFormData });
        }
      }
    }
  };
  //conditional refresh
  const onRefreshClicked = (event, trackNumbers, isAll) => {
    if (!isAll) {
      sendTrackInformationToRefresh({ tracking_no: trackNumbers }, userInfo?.accessToken);
    } else {
      trackNumbers.forEach((number) => {
        sendTrackInformationToRefresh({ tracking_no: number }, userInfo?.accessToken);
      });
    }
  };

  const onExpandhandler = (event, trackNumber, index) => {
    event.preventDefault();
    if (expandedRow !== trackNumber) {
      getSingleTrackNumberInfo(trackNumber);
      // navigate(`/dashboard/tracking/${trackNumber}`);
      setExpandedRow(trackNumber);
      setSearchKey(trackingNumber);
    } else {
      // navigate('/dashboard/tracking');
      setExpandedRow(null);
      setSearchKey(null);
      // if (statusToShow) {
      //   setStatusToShow(statusToShow);
      // }
      // setRawSingleTrackingInfo({});
    }
  };

  // Search
  const onhandeSeachSubmit = (e) => {
    const searchedVal = e.target.trackingNumber.value.trim();
    setSearchKey(searchedVal);
    setPage(0);
    setRowsPerPage(10);
    setStartDate(false);
    setEndDate(false);
    setTotalItemCount(0);
    setAdvacnedFromData({});
    setShowAdvancedFilter(false);
    if (pathname !== '/dashboard/tracking') {
      navigate('/dashboard/tracking');
    }
    setStatusList([]);
    setStatusAllData('All Orders');
    setStatusToShow('');

    // internal seach
    const filteredRows = mainTrackingList.filter((row) =>
      row[defaultColumn].toString().includes(searchedVal.toLowerCase())
    );
    if (filteredRows.length > 0) {
      setMainTrackingList(filteredRows);
      setTotalItemCount(filteredRows.length);
    } else {
      // external seach
      getTrackingNumerList(
        {
          page_size: rowsPerPage,
          current_page: 1,
          tracking_no: searchedVal,
        },
        false,
        true
      );
      // getSingleTrackNumberInfo(searchedVal);
    }

    // setExpandedRow(searchedVal);
  };

  const onSearchClick = () => {
    setSearchKey(null);
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setExpandedRow(null);
    if (newPage <= page || mainTrackingList.length > rowsPerPage * (page + 1)) {
      return;
    }
    let paginationParams = {
      page_size: rowsPerPage,
      current_page: newPage + 1,
      [sortDataString]: order,
    };
    if (startDate && endDate) {
      paginationParams.date_range = `${startDate}-${endDate}`;
    }
    if (first_scan) {
      paginationParams.first_scan = first_scan;
    } else if (statusToShow !== 'All Orders') {
      paginationParams.status = statusToShow;
    }
    if (Object.keys(advancedFormData).length > 0) {
      Object.assign(paginationParams, advancedFormData);
    }
    getTrackingNumerList(paginationParams, true);
  };

  const handleChangeRowsPerPage = (event) => {
    const selected_page = event.target.value;
    setRowsPerPage(selected_page);
    setPage(0);
    if (totalItemsCount > 1) {
      const queryParams = getCurrentPageParams();
      if (rowsPerPage >= selected_page) {
        return;
      }
      if (pathname === '/dashboard/tracking' || advancedFormData.hasOwnProperty('status')) {
        if (advancedFormData.hasOwnProperty('status')) {
          getTrackingNumerList(
            {
              ...queryParams,
              ...advancedFormData,
              page_size: selected_page,
              current_page: 1,
              [sortDataString]: order,
            },
            false
          );
        } else {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: selected_page,
              current_page: 1,
              status: statusToShow,
              [sortDataString]: order,
            },
            false
          );
          //setCurrentStatusData(statusToShow);
        }
      } else {
        // const queryParams = getCurrentPageParams();
        if (first_scan) {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: selected_page,
              current_page: 1,
              first_scan: first_scan,
              [sortDataString]: order,
            },
            false
          );
        } else if (statusToShow) {
          getTrackingNumerList(
            {
              ...queryParams,
              page_size: selected_page,
              current_page: 1,
              status: statusToShow,
              [sortDataString]: order,
            },
            false
          );
          setPage(0);
        }
      }
    }
  };

  const onClickAdvancedFilter = () => {
    if (pathname === '/dashboard/tracking') {
      if (!showAdvancedFilter) {
        setStartDate(false);
        setEndDate(false);
      } else {
        setStartDate(dayjs().subtract(7, 'day').format('MM/DD/YYYY'));
        setEndDate(dayjs().format('MM/DD/YYYY'));
        setStatusToShow('All Orders');
        setStatusAllData('All Orders');
        setStatusList(['Delivered', 'In Transit', 'Pre-Shipment', 'Alert']);
      }
    }
    setSearchKey(null);
    setAdvacnedFromData({});
    setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
    setPage(0);
  };

  const onChangeAdvancedForm = (e = null, date = null) => {
    if (date == null) {
      const { name, value } = e.target;
      let tempData = { ...advancedFormData };
      if ((value == null || value === '') && tempData.hasOwnProperty(name)) {
        delete tempData[name];
      } else {
        tempData[name] = value;
      }
      setAdvacnedFromData({ ...tempData });
    } else {
      setAdvacnedFromData({ ...advancedFormData, [e]: date !== 'Invalid Date' ? date : null });
    }
  };

  const onSubmitAdvancedFilter = () => {
    setMainTrackingList([]);
    const tabStatus = [];
    let tempFormData = getCurrentPageParams(false);
    if (tempFormData.hasOwnProperty('status') && tempFormData.status != '') {
      const statusValue = tempFormData.status.length === 1 ? tempFormData.status : 'All Orders';
      setStatusList([statusValue]);
      setStatusToShow(statusValue);
      setStatusAllData(statusValue);
    } else {
      setStatusAllData('All Orders');
      setStatusToShow('All Orders');
      setStatusList(['All Orders']);
      getTrackingNumerList({ ...tempFormData });
      if (!tabStatus.includes(statusToShow) && statusToShow !== 'All Orders') {
        setStatusToShow('All Orders');
      }
    }
    if (pathname !== '/dashboard/tracking') {
      navigate('/dashboard/tracking');
      getTrackingNumerList({ ...tempFormData });
      setExpandedRow(null);
      setPage(0);
    } else {
      if (tempFormData.hasOwnProperty('status') && tempFormData.status != '') {
        if (tempFormData.status.length == 1) {
          setStatusList([tempFormData.status]);
          setStatusToShow(tempFormData.status);
          setStatusAllData(tempFormData.status);
        } else {
          setStatusList(['All Orders']);
          setStatusToShow('All Orders');
          setStatusAllData('All Orders');
        }
        if (!advancedFormData) {
          getTrackingNumerList({ ...tempFormData });
        } else {
          getTrackingNumerList({ ...tempFormData });
        }
      } else {
        setStatusToShow('All Orders');
      }
      setExpandedRow(null);
      setPage(0);
    }
  };

  // Status filter
  const onStatusChange = (e) => {
    const mainStatus = e.target.name;
    const statusToAdd = mainStatus;
    setStatusToFetch(mainStatus);
    setStatusToShow(mainStatus);
    setSearchKey(null);

    let queryParams = getCurrentPageParams(statusToAdd);
    if (mainStatus === statusAllData) {
      navigate('/dashboard/tracking');
      if (pathname === '/dashboard/tracking') {
        setStatusToShow(mainStatus);
        if (mainStatus !== 'All orders') {
          getTrackingNumerList({ ...queryParams, status: mainStatus });
        } else {
          const { status, ...queryParamsWithoutStatus } = queryParams;
          getTrackingNumerList(queryParamsWithoutStatus);
        }
      } else {
        // fetchData(true);
      }
      setExpandedRow(null);
      setStatusList(['Delivered', 'In Transit', 'Pre-Shipment', 'Alert']);
    } else {
      //for these state stored api response
      // if (['Delivered', 'In Transit', 'Pre-Shipment', 'Alert'].includes(mainStatus)) {
      //   switch (mainStatus) {
      //     case 'Delivered':
      //       setMainTrackingList(initDelivered.data?.items || []);
      //       setTotalItemCount(initDelivered.data?.total_count || 0);
      //       break;
      //     case 'In Transit':
      //       setMainTrackingList(initInTransit.data?.items || []);
      //       setTotalItemCount(initInTransit.data?.total_count || 0);
      //       break;
      //     case 'Pre-Shipment':
      //       setMainTrackingList(initPreShipment.data?.items || []);
      //       setTotalItemCount(initPreShipment.data?.total_count || 0);
      //       break;
      //     case 'Alert':
      //       setMainTrackingList(initAlert.data?.items || []);
      //       setTotalItemCount(initAlert.data?.total_count || 0);
      //       break;
      //     default:
      //       setMainTrackingList([]);
      //       setTotalItemCount(0);
      //       break;
      //   }}
      if (trackingNumber) {
        getTrackingNumerList({ page_size: rowsPerPage, current_page: 1, status: mainStatus });
        navigate('/dashboard/tracking');
      } else if (first_scan) {
        //call the API if we have not stored data
        getTrackingNumerList({ ...queryParams, page_size: rowsPerPage, current_page: 1, first_scan: mainStatus });
      } else if (!showAdvancedFilter) {
        getTrackingNumerList({ ...queryParams, page_size: rowsPerPage, current_page: 1, status: mainStatus });
      } else if (showAdvancedFilter) {
        getTrackingNumerList({ ...queryParams, page_size: rowsPerPage, current_page: 1, status: mainStatus });
      }
    }

    setPage(0);
  };

  const onCloseExportInfo = () => {
    setShowExportInfo(false);
  };

  const handleColumnSubmit = (columnList) => {
    setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
    dispatch(
      updateUserPreferences({ trackingInfo: columnList.map((item) => removeTitleCase(item)), mailScan: mailScan })
    );
    updateColumnPreferences(
      { table_name: 'tracking_info', table_columns: columnList.map((item) => removeTitleCase(item)) },
      userInfo?.accessToken
    );
    // useAppDispatch(updateColumnPreferences({table_name:'tracking_info',table_columns:columnList.map(item => removeTitleCase(item))}))
  };

  return (
    <>
      <Helmet>
        <title> Tracking | Shipping Insights </title>
      </Helmet>
      <Snackbar
        open={showExportInfo}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={onCloseExportInfo}
      >
        <Alert onClose={onCloseExportInfo} severity="success" sx={{ width: '100%' }}>
          Export in progress
        </Alert>
      </Snackbar>

      <Container maxWidth="xl">
        <SmartTable
          isSearch
          isAdvancedFilter
          isStatusMenu
          isRowSelectable
          refreshAction
          originalRows={typeof dataConverted !== 'undefined' ? dataConverted : [{}]}
          defaultColumn={defaultColumn}
          statusColumn={statusColumn}
          statusList={statusList}
          statusAllData={statusAllData}
          getDateRange={getDateRange}
          onRefreshClicked={onRefreshClicked}
          onExpandhandler={onExpandhandler}
          expandedRow={expandedRow}
          totelItemCount={totalItemsCount}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          onhandeSeachSubmit={onhandeSeachSubmit}
          searchKey={searchKey}
          onSearchClick={onSearchClick}
          showLoader={showLoader}
          startDate={startDate}
          endDate={endDate}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          carrierSelect={carrierSelect}
          statusSelect={statusSelect}
          scannedStatus={scannedStatus}
          firstScanEmailStatistics={firstScanEmailStatistics}
          onSubmitAdvancedFilter={onSubmitAdvancedFilter}
          exapndRowIntputData={rawSingleTrackingInfo}
          trackingInfoRef={trackingInfoRef}
          showAdvancedFilter={showAdvancedFilter}
          onClickAdvancedFilter={onClickAdvancedFilter}
          onStatusChange={onStatusChange}
          statusToShow={statusToShow}
          exportButtonClicked={exportButtonClicked}
          trackingPage={true}
          userSelectedColumns={userSelectedColumns}
          handleColumnSubmit={handleColumnSubmit}
          modalLoader={modalLoader}
          sortOrder={sortOrder}
          style={{ cursor: 'pointer' }}
        />
      </Container>
    </>
  );
}

export default TrackingPage;
