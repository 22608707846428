// import { Helmet } from 'react-helmet-async';
/* eslint-disable camelcase */
/* eslint-disable no-loss-of-precision */
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef, useContext } from 'react';
import dayjs from 'dayjs';
import { unixToDate, cleanObject, removeTitleCase } from 'src/utils/core';
// @mui
import { Container, Snackbar, Alert } from '@mui/material';
import getTrackingList, {
  getInTransitAlertTrackingList,
  getTrackingInfo,
  sendTrackInformationToRefresh,
} from 'src/features/tracking/trackingApi';
import SmartTable from '../../components/smart-table/SmartTable';
import { SocketContext } from '../../components/socket-handler/sockethandler';
import { useSelector } from 'react-redux';
import { updateColumnPreferences } from 'src/features/tableActions/userColPreferenceApi';
import { logoutUser, updateUserPreferences } from 'src/features/user/userState';
import { useAppSelector, useAppDispatch } from 'src/hooks/hooks';
import { getUserInfo } from 'src/features/user/userState';
import { incrementAwaitingApiCounter, decrementAwaitingApiCounter } from 'src/features/ui/uiState';

// ----------------------------------------------------------------------

function Reports(callback, deps) {
  const socket = useContext(SocketContext);
  const { pathname } = useLocation();
  const trackingInfo = useSelector((state) => state.user.trackingInfo);
  const mailScan = useAppSelector((state) => state.user.mailScan);
  const [expandedRow, setExpandedRow] = useState(null);
  const [mainTrackingList, setMainTrackingList] = useState([]);
  const [totalItemsCount, setTotalItemCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showLoader, setShowLoader] = useState(false);
  const [startDate, setStartDate] = useState(dayjs().subtract(30, 'day').format('MM/DD/YYYY'));
  const [endDate, setEndDate] = useState(
    pathname === '/dashboard/reports/preshipment'
      ? dayjs().subtract(5, 'day').format('MM/DD/YYYY')
      : dayjs().subtract(10, 'day').format('MM/DD/YYYY')
  );
  const [advancedFormData, setAdvacnedFromData] = useState({});
  const [rawSingleTrackingInfo, setRawSingleTrackingInfo] = useState({});
  const [refreshTrackingInfo, setRefreshTrackingInfo] = useState({});
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [statusToFetch, setStatusToFetch] = useState(
    pathname === '/dashboard/reports/preshipment' ? 'Pre-Shipment' : 'In Transit'
  );
  const [showExportInfo, setShowExportInfo] = useState(false);
  const [userSelectedColumns, setUserSelectedColumns] = useState([]);
  const [alertStatus, setAlertStatus] = useState(
    pathname === '/dashboard/reports/preshipment' ? 'preShipmentAlert' : 'inTransitAlert'
  );
  const userInfo = useAppSelector(getUserInfo);
  const dispatch = useAppDispatch();
  const [modalLoader, setModalLoader] = useState(false);
  const [order, setOrder] = useState('');
  const [product, setProduct] = useState('');
  const sortDataString = `sort_data[${product}]`;

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    setShowAdvancedFilter(false);

    const queryParams = getCurrentPageParams();
    if (pathname === '/dashboard/reports/preshipment') {
      const sDate = dayjs().subtract(30, 'day').format('MM/DD/YYYY');
      const eDate = dayjs().subtract(5, 'day').format('MM/DD/YYYY');
      queryParams['date_range'] = `${sDate}-${eDate}`;

      setStartDate(sDate);
      setEndDate(eDate);
      setAlertStatus('preShipmentAlert');
      getTrackingNumerList(
        {
          ...queryParams,
          page_size: 10,
          current_page: 1,
          preShipmentAlert: true,
          status: 'Pre-Shipment',
          [sortDataString]: order,
        },
        false
      );
      setStatusToFetch('Pre-Shipment');
    } else if (pathname === '/dashboard/reports/intransit') {
      const sDate = dayjs().subtract(30, 'day').format('MM/DD/YYYY');
      const eDate = dayjs().subtract(10, 'day').format('MM/DD/YYYY');
      queryParams['date_range'] = `${sDate}-${eDate}`;
      setStartDate(sDate);
      setEndDate(eDate);
      setAlertStatus('inTransitAlert');
      getTrackingNumerList(
        {
          ...queryParams,
          page_size: 10,
          current_page: 1,
          inTransitAlert: true,
          status: 'In Transit',
          [sortDataString]: order,
        },
        false
      );
      setStatusToFetch('In Transit');
    }
  }, [pathname]);

  useEffect(() => {
    const queryParams = getCurrentPageParams();
    {
      getTrackingNumerList(
        {
          ...queryParams,
          page_size: rowsPerPage,
          current_page: 1,
          status: statusToFetch,
          [alertStatus]: true,
          [sortDataString]: order,
        },
        false
      );
    }
    setPage(0);
  }, [order]);

  useEffect(() => {
    if (typeof trackingInfo !== 'undefined' && trackingInfo.length > 0) {
      setUserSelectedColumns(trackingInfo);
    }
  }, [trackingInfo]);
  const trackingInfoRef = useRef({});

  const sortOrder = (columnName, sortOrder) => {
    setOrder(sortOrder);
    setProduct(columnName);
  };

  useEffect(() => {
    if (typeof refreshTrackingInfo.trackNo !== 'undefined') {
      const { trackNo } = refreshTrackingInfo;
      const index = mainTrackingList.findIndex((object) => object.tracking_number === trackNo);
      if (index > -1) {
        const tableList = [...mainTrackingList];
        tableList[index] = refreshTrackingInfo.tableResponseData;
        setMainTrackingList(tableList);
        if (
          rawSingleTrackingInfo.hasOwnProperty('tracking_number') &&
          rawSingleTrackingInfo.tracking_number === trackNo
        ) {
          setRawSingleTrackingInfo({ ...refreshTrackingInfo.trackNoInfo });
        }
      }
    }
  }, [refreshTrackingInfo]);

  useEffect(() => {
    function cbHandleRefreshTrackingDetails(data) {
      console.log('socket data ', data);
      setRefreshTrackingInfo(data);
    }
    socket.on('refresh_tracking_details', cbHandleRefreshTrackingDetails);
    return () => {
      socket.off('refresh_tracking_details', cbHandleRefreshTrackingDetails);
    };
  }, []);

  const exportButtonClicked = () => {
    setShowExportInfo(true);
    let parmas = { export_data: true, export_data_to: userInfo?.user?.user_mail, ...advancedFormData };
    if (startDate !== false && endDate !== false) {
      parmas.date_range = `${startDate}-${endDate}`;
    }

    parmas.status = statusToFetch;

    exportTrackingNumerList(parmas);
  };

  const exportTrackingNumerList = (parms, appendRow = false) => {
    let res;
    if (parms.status == 'In Transit') {
      parms['inTransitAlert'] = true;
      res = getInTransitAlertTrackingList(cleanObject(parms), userInfo?.accessToken);
    } else {
      parms['preShipmentAlert'] = true;
      res = getTrackingList(cleanObject(parms), userInfo?.accessToken);
    }
    res
      .then((result) => {
        console.log(result.statusCode);
      })
      .catch((error) => {
        if (error?.response?.status == '401') {
          dispatch(logoutUser());
        }
        console.log(error);
      });
  };
  const getSingleTrackNumberInfo = (trackNumber) => {
    setModalLoader(true);
    var parmas = null;
    const res = getTrackingInfo(trackNumber, parmas, userInfo?.accessToken);
    res
      .then((result) => {
        setRawSingleTrackingInfo(result.data);
        setModalLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == '401') {
          dispatch(logoutUser());
        }
        setModalLoader(false);
      });
  };
  // get Tracking number list
  const getTrackingNumerList = (parms, appendRow = false) => {
    dispatch(incrementAwaitingApiCounter());
    setShowLoader(true);
    let res;
    if (parms.status == 'In Transit') {
      delete parms.preShipmentAlert;
      res = getInTransitAlertTrackingList(cleanObject(parms), userInfo?.accessToken);
    } else {
      delete parms.inTransitAlert;
      res = getTrackingList(cleanObject(parms), userInfo?.accessToken);
    }
    res
      .then((result) => {
        if (mainTrackingList?.length > 0 && appendRow) {
          setMainTrackingList([...new Set(mainTrackingList), ...result?.data?.items]);
        } else {
          setMainTrackingList(result?.data?.items);
        }
        if (result?.data?.current_page == 1) {
          setTotalItemCount(result?.data?.total_count);
        }
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status == '401') {
          dispatch(logoutUser());
        }
        dispatch(decrementAwaitingApiCounter());
        setShowLoader(false);
      });
  };

  // formate key names
  const originalRows1 = mainTrackingList?.map(
    ({
      customer_first_name: first_name,
      customer_last_name: last_name,
      customer_email: email,
      order_id: order_number,
      carrier,
      shipping_date: shipped_on,
      shipping_street: street,
      shipping_city: city,
      shipping_state: state,
      shipping_country: country,
      shipping_postal_code: zip_code,
      shipping_telephone: telephone,
      tracking_number,
      status,
      estimated_delivery_date,
      scanned_status,
    }) => ({
      first_name,
      last_name,
      email,
      order_number,
      carrier,
      shipped_on,
      street,
      state,
      city,
      country,
      zip_code,
      telephone,
      tracking_number,
      status,
      estimated_delivery_date,
      scanned_status,
    })
  );

  // change scan status
  const scanStatus = {
    1: 'Scanned',
    2: 'Un Scanned',
    3: 'Delivered without Scan',
    4: 'Invalid Email',
    5: 'Delivered',
    6: 'Un Scanned Exit met',
  };

  const dataConverted = [];

  const allFormatetted = originalRows1?.forEach((item) => {
    dataConverted.push({
      ...item,
      scanned_status: scanStatus[item.scanned_status],
      shipped_on: `${unixToDate(item.shipped_on)}`,
      estimated_delivery_date: item.estimated_delivery_date ? `${unixToDate(item.estimated_delivery_date)}` : '--',
      order_number: Object.values(item.order_number).toString(),
    });
  });

  const defaultColumn = 'tracking_number';
  const statusColumn = 'status';

  const carrierSelect = [
    {
      label: 'Select Carrier',
      value: '',
    },
    {
      label: 'UPS',
      value: 'UPS',
    },
    {
      label: 'USPS',
      value: 'USPS',
    },
  ];

  let statusSelect = [];
  if (pathname === '/dashboard/reports/preshipment') {
    statusSelect.push({
      label: 'Pre-Shipment',
      value: 'Pre-Shipment',
    });
  } else if (pathname === '/dashboard/reports/intransit') {
    statusSelect.push({
      label: 'In Transit',
      value: 'In Transit',
    });
  }

  let scannedStatus = [
    {
      label: 'Select Status',
      value: '',
    },
  ];
  if (alertStatus === 'preShipmentAlert') {
    scannedStatus.push(
      {
        label: 'Un Scanned',
        value: 2,
      },
      {
        label: 'Un Scanned Exit Met',
        value: 6,
      }
    );
  } else if (alertStatus === 'inTransitAlert') {
    scannedStatus.push(
      {
        label: 'Scanned',
        value: 1,
      },
      {
        label: 'Invalid Email',
        value: 4,
      }
    );
  }

  const getDateRange = (dates) => {
    if (dates != null && dates.length > 0) {
      const formattedStartDate = dayjs(dates[0]).format('MM/DD/YYYY');
      const formattedEndDate = dayjs(dates[1]).format('MM/DD/YYYY');
      if (!showAdvancedFilter) {
        getTrackingNumerList(
          {
            page_size: rowsPerPage,
            current_page: 1,
            date_range: `${formattedStartDate}-${formattedEndDate}`,
            status: statusToFetch,
            [alertStatus]: true,
          },
          false
        );
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        setPage(0);
      } else {
        setAdvacnedFromData({
          ...advancedFormData,
          date_range: `${formattedStartDate}-${formattedEndDate}`,
          status: statusToFetch,
        });
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      }
    }
    // else {
    //   setStartDate();
    //     setEndDate();
    //   getTrackingNumerList({
    //     page_size: rowsPerPage,
    //     current_page: page + 1,
    //     status: statusToFetch,
    //     [alertStatus]: true,
    //   }); //if start date and end date cleared then
    // }
  };
  //conditional refresh
  const onRefreshClicked = (event, trackNumbers, isAll) => {
    if (!isAll) {
      sendTrackInformationToRefresh({ tracking_no: trackNumbers }, userInfo?.accessToken);
    } else {
      trackNumbers.forEach((number) => {
        sendTrackInformationToRefresh({ tracking_no: number }, userInfo?.accessToken);
      });
    }
  };

  const onExpandhandler = (event, trackNumber, index) => {
    event.preventDefault();
    if (expandedRow !== trackNumber) {
      getSingleTrackNumberInfo(trackNumber);
      setExpandedRow(trackNumber);
    } else {
      setExpandedRow(null);
      setRawSingleTrackingInfo({});
    }
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setExpandedRow(null);
    if (newPage <= page || mainTrackingList.length > rowsPerPage * (page + 1)) {
      return;
    }
    const paginationParams = {
      page_size: rowsPerPage,
      status: statusToFetch,
      current_page: newPage + 1,
      [alertStatus]: true,
    };
    if (startDate && endDate) {
      paginationParams.date_range = `${startDate}-${endDate}`;
    }
    if (Object.keys(advancedFormData).length > 0) {
      Object.assign(paginationParams, advancedFormData);
    }
    getTrackingNumerList(paginationParams, true);
  };
  const getCurrentPageParams = (isStatusStrict = true, current_page = 1) => {
    let tempFormData = { ...advancedFormData };
    let queryParams = {
      page_size: rowsPerPage,
      current_page,
      status: statusToFetch,
      [sortDataString]: order,
      [alertStatus]: true,
      ...tempFormData,
    };
    if (startDate != false && endDate != false) {
      queryParams['date_range'] = `${startDate}-${endDate}`;
    } else {
      if (queryParams.hasOwnProperty('date_range')) {
        delete queryParams['date_range'];
      }
    }
    if (alertStatus) {
      queryParams[alertStatus] = true;
    }
    return queryParams;
  };
  const handleChangeRowsPerPage = (event) => {
    const selected_page = event.target.value;
    setRowsPerPage(selected_page);
    setPage(0);
    if (totalItemsCount > 1) {
      {
        const queryParams = getCurrentPageParams();
        getTrackingNumerList(
          {
            ...queryParams,
            page_size: selected_page,
            current_page: 1,
            status: statusToFetch,
            [sortDataString]: order,
          },
          false
        );
      }
    }
  };

  const onClickAdvancedFilter = () => {
    setAdvacnedFromData({});
    setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
    setPage(0);
  };

  const onChangeAdvancedForm = (e = null, date = null) => {
    if (date == null) {
      const { name, value } = e.target;
      setAdvacnedFromData({ ...advancedFormData, [name]: value });
    } else {
      setAdvacnedFromData({ ...advancedFormData, [e]: date !== 'Invalid Date' ? date : null });
    }
  };

  const onSubmitAdvancedFilter = () => {
    setMainTrackingList([]);
    if (pathname !== '/dashboard/tracking') {
      getTrackingNumerList({
        ...advancedFormData,
        page_size: rowsPerPage,
        current_page: 1,
        date_range: `${startDate}-${endDate}`,
        [sortDataString]: order,
        [alertStatus]: true,
        status: statusToFetch,
      });
      setExpandedRow(null);
      setPage(0);
    }
  };

  const onCloseExportInfo = () => {
    setShowExportInfo(false);
  };

  const handleColumnSubmit = (columnList) => {
    setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
    dispatch(
      updateUserPreferences({ trackingInfo: columnList.map((item) => removeTitleCase(item)), mailScan: mailScan })
    );
    updateColumnPreferences(
      { table_name: 'tracking_info', table_columns: columnList.map((item) => removeTitleCase(item)) },
      userInfo?.accessToken
    );
  };

  return (
    <>
      <Helmet>
        <title> Tracking | Shipping Insights </title>
      </Helmet>
      <Snackbar
        open={showExportInfo}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={onCloseExportInfo}
      >
        <Alert onClose={onCloseExportInfo} severity="success" sx={{ width: '100%' }}>
          Export in progress
        </Alert>
      </Snackbar>

      <Container maxWidth="xl">
        <SmartTable
          isStatusMenu={false}
          isSearch={false}
          isAdvancedFilter
          isRowSelectable
          isAction
          originalRows={typeof dataConverted !== 'undefined' ? dataConverted : [{}]}
          defaultColumn={defaultColumn}
          statusColumn={statusColumn}
          getDateRange={getDateRange}
          onRefreshClicked={onRefreshClicked}
          onExpandhandler={onExpandhandler}
          expandedRow={expandedRow}
          totelItemCount={totalItemsCount}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          showLoader={showLoader}
          startDate={startDate}
          endDate={endDate}
          onChangeAdvancedForm={onChangeAdvancedForm}
          advancedFormData={advancedFormData}
          carrierSelect={carrierSelect}
          statusSelect={statusSelect}
          scannedStatus={scannedStatus}
          onSubmitAdvancedFilter={onSubmitAdvancedFilter}
          exapndRowIntputData={rawSingleTrackingInfo}
          trackingInfoRef={trackingInfoRef}
          showAdvancedFilter={showAdvancedFilter}
          onClickAdvancedFilter={onClickAdvancedFilter}
          exportButtonClicked={exportButtonClicked}
          trackingPage={true}
          userSelectedColumns={userSelectedColumns}
          handleColumnSubmit={handleColumnSubmit}
          modalLoader={modalLoader}
          sortOrder={sortOrder}
          style={{ cursor: 'pointer' }}
        />
      </Container>
    </>
  );
}

export default Reports;
