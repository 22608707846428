import { createSlice } from '@reduxjs/toolkit';
import { login } from '../user/userState';
import { getTracking } from '../tracking/trakingState';
import { fetchScanStatus, fetchShippingStatistic } from '../scan/scanState';
import { getConfig, putConfigData } from '../config/configState';
import { fetchReplacementOrders } from '../replacementOrder/replacementState';

const initialState = {
  awaitingApis: 0,
  manualLogout: false,
};

const incrementCounter = (state) => (state.awaitingApis += 1);
const decrementCounter = (state) => (state.awaitingApis = state.awaitingApis > 0 ? state.awaitingApis - 1 : 0);

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    incrementAwaitingApiCounter: (state) => {
      incrementCounter(state);
    },
    decrementAwaitingApiCounter: (state) => {
      decrementCounter(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(login.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(login.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getTracking.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getTracking.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getTracking.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(fetchScanStatus.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(fetchScanStatus.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(fetchScanStatus.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(getConfig.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(getConfig.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(getConfig.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(putConfigData.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(putConfigData.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(putConfigData.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(fetchShippingStatistic.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(fetchShippingStatistic.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(fetchShippingStatistic.rejected, (state) => {
        decrementCounter(state);
      })
      .addCase(fetchReplacementOrders.pending, (state) => {
        incrementCounter(state);
      })
      .addCase(fetchReplacementOrders.fulfilled, (state) => {
        decrementCounter(state);
      })
      .addCase(fetchReplacementOrders.rejected, (state) => {
        decrementCounter(state);
      });
  },
});

export const { incrementAwaitingApiCounter, decrementAwaitingApiCounter } = uiSlice.actions;

export const getUI = (state) => state.ui;
export default uiSlice.reducer;
