/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, isLight, ...other }, ref) => {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logoDark = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: { lg: 244, sm: 172, md: 172 },
        height: 'auto',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg width="244" height="52" viewBox="0 0 244 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_496_25274)">
          <path d="M84.8932 19.8406H88.5974L84.7255 40.7524H81.0212L84.8932 19.8406Z" fill="black" />
          <path
            d="M128.658 20.7428H140.431L139.856 23.855H131.787L130.703 29.6998H137.116L136.541 32.812H130.128L128.658 40.7524H124.954L128.658 20.7428Z"
            fill="black"
          />
          <path
            d="M57.3356 33.3346H55.0553L53.683 40.7498H49.9788L53.683 20.7402H60.0493C63.6839 20.7402 65.9926 22.8046 65.9926 26.091C65.9926 29.2032 63.9445 31.85 60.9135 32.8094L64.1173 40.7498H60.2222L57.3356 33.3346ZM55.6151 30.3108H57.9135C60.4827 30.3108 62.1826 28.652 62.1826 26.4732C62.1826 24.7858 60.9986 23.7692 59.0665 23.7692H56.8275L55.6151 30.3134V30.3108Z"
            fill="black"
          />
          <path
            d="M91.2311 26.2106H94.9354L92.2423 40.7524H88.5381L91.2311 26.2106ZM94.0222 20.0746C95.1185 20.0746 95.8976 20.8598 95.8976 21.9648C95.8976 23.2726 94.8889 24.2918 93.5605 24.2918C92.4642 24.2918 91.7135 23.5066 91.7135 22.4016C91.7135 21.0938 92.6937 20.0746 94.0222 20.0746Z"
            fill="black"
          />
          <path
            d="M103.956 25.8622C107.129 25.8622 109.324 28.1606 109.324 31.447C109.324 32.435 109.208 33.4542 108.95 34.502H99.5734C99.545 34.619 99.545 34.7646 99.545 34.9388C99.545 36.9174 100.959 38.2252 103.151 38.2252C104.536 38.2252 106.051 37.7598 107.552 36.8862L106.959 40.0842C105.429 40.7524 103.786 41.1008 102.168 41.1008C98.5622 41.1008 95.9646 38.5424 95.9646 35.022C95.9646 29.6712 99.3129 25.8596 103.956 25.8596V25.8622ZM105.888 32.0866C105.917 31.824 105.945 31.5328 105.945 31.2728C105.945 29.7596 105.05 28.743 103.665 28.743C101.934 28.743 100.492 30.0222 99.9706 32.0866H105.886H105.888Z"
            fill="black"
          />
          <path
            d="M74.397 25.8622C77.5698 25.8622 79.765 28.1606 79.765 31.447C79.765 32.435 79.6489 33.4542 79.391 34.502H70.0143C69.9859 34.619 69.9859 34.7646 69.9859 34.9388C69.9859 36.9174 71.3995 38.2252 73.5922 38.2252C74.9774 38.2252 76.4916 37.7598 77.9929 36.8862L77.3996 40.0842C75.8699 40.7524 74.2267 41.1008 72.6093 41.1008C69.0031 41.1008 66.4055 38.5424 66.4055 35.022C66.4055 29.6712 69.7538 25.8596 74.397 25.8596V25.8622ZM76.3291 32.0866C76.3574 31.824 76.3858 31.5328 76.3858 31.2728C76.3858 29.7596 75.4907 28.743 74.1055 28.743C72.3746 28.743 70.9326 30.0222 70.4116 32.0866H76.3265H76.3291Z"
            fill="black"
          />
          <path
            d="M150.979 40.7524H147.458L147.636 39.793C146.452 40.6354 145.237 41.1008 143.968 41.1008C140.908 41.1008 138.775 38.688 138.775 35.1676C138.775 29.8454 141.979 25.8622 146.191 25.8622C147.46 25.8622 148.667 26.2938 149.792 27.1648L149.968 26.2106H153.672L150.979 40.7524ZM145.237 37.9028C146.16 37.9028 147.161 37.4088 148.056 36.5352L149.243 30.1288C148.464 29.4008 147.548 29.0602 146.681 29.0602C144.344 29.0602 142.585 31.4444 142.585 34.7022C142.585 36.738 143.594 37.9002 145.239 37.9002L145.237 37.9028Z"
            fill="black"
          />
          <path
            d="M163.105 25.8622C164.547 25.8622 166.043 26.2626 167.485 27.1076L166.9 30.2692C165.716 29.3956 164.491 28.9744 163.335 28.9744C160.508 28.9744 158.46 31.3014 158.46 34.4994C158.46 36.5924 159.788 37.9886 161.836 37.9886C163.049 37.9886 164.349 37.6402 165.677 36.8836L165.068 40.17C163.683 40.8096 162.27 41.1008 160.856 41.1008C157.25 41.1008 154.652 38.571 154.652 34.9934C154.652 29.6998 158.202 25.8622 163.105 25.8622Z"
            fill="black"
          />
          <path
            d="M173.991 26.2106H178.121L177.566 29.2058H173.436L172.242 35.6616C172.183 35.9658 172.154 36.2154 172.154 36.4754C172.154 37.3776 172.817 37.9886 173.8 37.9886C174.522 37.9886 175.276 37.7858 176.083 37.4062L175.49 40.6042C174.509 40.924 173.498 41.0982 172.518 41.0982C170.008 41.0982 168.334 39.5278 168.334 37.2008C168.334 36.8524 168.388 36.4728 168.445 36.153L171.045 22.1052H174.749L173.991 26.2054V26.2106Z"
            fill="black"
          />
          <path
            d="M196.526 26.2106H200.07L199.81 27.6224C201.02 26.4888 202.314 25.8804 203.555 25.8804C204.045 25.8804 204.504 25.9662 204.935 26.1716L204.332 29.4242C203.898 29.25 203.439 29.1616 203.006 29.1616C201.796 29.1616 200.516 29.8298 199.335 31.0206L197.535 40.7498H193.835L196.529 26.208L196.526 26.2106Z"
            fill="black"
          />
          <path
            d="M219.174 20.3944C221.106 20.3944 223.064 20.943 224.971 22.1052L224.359 25.415C222.714 24.2216 220.993 23.5924 219.407 23.5924C215.078 23.5924 211.905 27.3156 211.905 32.4636C211.905 35.75 213.953 37.9028 217.098 37.9028C218.713 37.9028 220.518 37.4088 222.337 36.3324L221.684 39.8528C219.925 40.7264 218.019 41.1034 216.115 41.1034C211.413 41.1034 208.036 37.6714 208.036 32.9316C208.036 25.6308 212.71 20.397 219.174 20.397V20.3944Z"
            fill="black"
          />
          <path
            d="M232.818 25.8622C236.452 25.8622 239.022 28.3634 239.022 31.9696C239.022 37.2632 235.5 41.1008 230.568 41.1008C226.903 41.1008 224.365 38.5996 224.365 34.9934C224.365 29.6998 227.857 25.8622 232.818 25.8622ZM230.999 37.9912C233.365 37.9912 235.212 35.5472 235.212 32.292C235.212 30.2848 234.115 28.977 232.384 28.977C230.019 28.977 228.172 31.421 228.172 34.6762C228.172 36.6548 229.268 37.9912 230.999 37.9912Z"
            fill="black"
          />
          <path
            d="M186.744 25.8622C190.379 25.8622 192.948 28.3634 192.948 31.9696C192.948 37.2632 189.427 41.1008 184.495 41.1008C180.829 41.1008 178.291 38.5996 178.291 34.9934C178.291 29.6998 181.784 25.8622 186.744 25.8622ZM184.926 37.9912C187.291 37.9912 189.138 35.5472 189.138 32.292C189.138 30.2848 188.042 28.977 186.311 28.977C183.945 28.977 182.098 31.421 182.098 34.6762C182.098 36.6548 183.195 37.9912 184.926 37.9912Z"
            fill="black"
          />
          <path
            d="M242.04 36.6522C243.164 36.6522 244.003 37.466 244.003 38.6308C244.003 40.027 242.963 41.1034 241.549 41.1034C240.394 41.1034 239.586 40.261 239.586 39.0962C239.586 37.7 240.626 36.6522 242.04 36.6522Z"
            fill="black"
          />
          <path
            d="M117.279 29.2058H120.932L121.487 26.2106H117.834L118.167 24.4062C118.368 23.3012 119.201 22.6018 120.298 22.6018C121.02 22.6018 121.768 22.8332 122.519 23.2414L123.068 20.2748C121.884 19.7808 120.731 19.4896 119.635 19.4896C116.952 19.4896 115.066 21.0886 114.576 23.7952L114.13 26.208H111.677L111.122 29.2032H113.575L111.437 40.7498H115.141L117.279 29.2032V29.2058Z"
            fill="black"
          />
          <path
            d="M24.9652 0C24.9652 0 7.10433 8.2264 1.23069 26.4732C-2.07372 36.7432 1.52476 47.0756 9.11122 49.5092C12.9006 50.726 16.4707 49.387 16.4707 49.387C12.8206 45.734 12.137 38.3188 16.334 31.421C20.5619 24.4712 26.7631 20.6804 30.4957 18.8006C29.6806 10.0308 26.3401 2.509 24.9652 0Z"
            fill="#00AAA0"
          />
          <path
            d="M39.0183 15.6208C38.8068 15.6494 35.2083 16.4476 30.4955 18.8032C30.7947 21.7672 31.143 28.5246 28.8626 35.4666C26.5049 42.6478 21.7663 47.5774 16.4705 49.3896C21.8462 54.7482 31.1172 51.4046 35.7707 43.4928C43.1147 31.005 39.3795 16.9364 39.0183 15.6208Z"
            fill="#00AAA0"
          />
        </g>
        <defs>
          <clipPath id="clip0_496_25274">
            <rect width="244" height="52" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );

  const logoLight = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 200,
        height: 'auto',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {isDesktop ? (
        <svg width="150" height="32" viewBox="0 0 150 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_2231_2888)">
            <path d="M52.1889 12.1971H54.4661L52.0858 25.0527H49.8086L52.1889 12.1971Z" fill="white" />
            <path
              d="M79.0926 12.7517H86.3302L85.9765 14.6649H81.0162L80.3502 18.2581H84.2924L83.9388 20.1713H79.9965L79.0926 25.0527H76.8154L79.0926 12.7517Z"
              fill="white"
            />
            <path
              d="M35.2473 20.4926H33.8454L33.0018 25.0511H30.7246L33.0018 12.7501H36.9155C39.1499 12.7501 40.5692 14.0192 40.5692 16.0395C40.5692 17.9528 39.3101 19.5799 37.4468 20.1697L39.4163 25.0511H37.0218L35.2473 20.4926ZM34.1896 18.6337H35.6025C37.182 18.6337 38.227 17.6139 38.227 16.2745C38.227 15.2372 37.4991 14.6122 36.3114 14.6122H34.9349L34.1896 18.6353V18.6337Z"
              fill="white"
            />
            <path
              d="M56.0843 16.1131H58.3615L56.7059 25.0527H54.4287L56.0843 16.1131ZM57.8001 12.3409C58.4741 12.3409 58.953 12.8236 58.953 13.503C58.953 14.3069 58.3329 14.9335 57.5162 14.9335C56.8423 14.9335 56.3808 14.4508 56.3808 13.7715C56.3808 12.9675 56.9834 12.3409 57.8001 12.3409Z"
              fill="white"
            />
            <path
              d="M63.9069 15.8989C65.8574 15.8989 67.207 17.3119 67.207 19.3322C67.207 19.9396 67.1356 20.5661 66.977 21.2103H61.2127C61.1952 21.2822 61.1952 21.3717 61.1952 21.4788C61.1952 22.6951 62.0642 23.4991 63.4122 23.4991C64.2637 23.4991 65.1946 23.213 66.1175 22.676L65.7528 24.6419C64.8124 25.0527 63.8023 25.2669 62.808 25.2669C60.591 25.2669 58.9941 23.6941 58.9941 21.5299C58.9941 18.2405 61.0525 15.8973 63.9069 15.8973V15.8989ZM65.0947 19.7254C65.1121 19.5639 65.1296 19.3849 65.1296 19.2251C65.1296 18.2948 64.5793 17.6699 63.7277 17.6699C62.6637 17.6699 61.7772 18.4563 61.4569 19.7254H65.0931H65.0947Z"
              fill="white"
            />
            <path
              d="M45.736 15.8989C47.6865 15.8989 49.0361 17.3119 49.0361 19.3322C49.0361 19.9396 48.9647 20.5661 48.8061 21.2103H43.0418C43.0243 21.2822 43.0243 21.3717 43.0243 21.4788C43.0243 22.6951 43.8933 23.4991 45.2413 23.4991C46.0928 23.4991 47.0237 23.213 47.9466 22.676L47.5819 24.6419C46.6415 25.0527 45.6314 25.2669 44.6371 25.2669C42.4201 25.2669 40.8232 23.6941 40.8232 21.5299C40.8232 18.2405 42.8816 15.8973 45.736 15.8973V15.8989ZM46.9238 19.7254C46.9412 19.5639 46.9587 19.3849 46.9587 19.2251C46.9587 18.2948 46.4084 17.6699 45.5568 17.6699C44.4928 17.6699 43.6063 18.4563 43.286 19.7254H46.9222H46.9238Z"
              fill="white"
            />
            <path
              d="M92.8149 25.0527H90.6503L90.7597 24.4629C90.0318 24.9808 89.2849 25.2669 88.5047 25.2669C86.624 25.2669 85.3125 23.7836 85.3125 21.6194C85.3125 18.3476 87.2821 15.8989 89.8716 15.8989C90.6519 15.8989 91.394 16.1642 92.0854 16.6997L92.1933 16.1131H94.4704L92.8149 25.0527ZM89.2849 23.3009C89.8526 23.3009 90.4679 22.9972 91.0182 22.4602L91.7476 18.5218C91.2687 18.0743 90.7058 17.8649 90.173 17.8649C88.7362 17.8649 87.6547 19.3306 87.6547 21.3333C87.6547 22.5848 88.2748 23.2993 89.2865 23.2993L89.2849 23.3009Z"
              fill="white"
            />
            <path
              d="M100.27 15.8989C101.156 15.8989 102.076 16.145 102.963 16.6645L102.603 18.6081C101.875 18.0711 101.121 17.8121 100.411 17.8121C98.673 17.8121 97.4139 19.2427 97.4139 21.2086C97.4139 22.4953 98.2305 23.3536 99.4897 23.3536C100.235 23.3536 101.034 23.1395 101.851 22.6743L101.477 24.6947C100.625 25.0879 99.7561 25.2669 98.8871 25.2669C96.6701 25.2669 95.0732 23.7117 95.0732 21.5123C95.0732 18.2581 97.2553 15.8989 100.27 15.8989Z"
              fill="white"
            />
            <path
              d="M106.962 16.1131H109.501L109.16 17.9544H106.621L105.887 21.9231C105.85 22.1101 105.833 22.2636 105.833 22.4234C105.833 22.978 106.24 23.3537 106.845 23.3537C107.289 23.3537 107.752 23.229 108.248 22.9956L107.883 24.9616C107.281 25.1582 106.659 25.2653 106.057 25.2653C104.514 25.2653 103.484 24.2999 103.484 22.8693C103.484 22.6552 103.518 22.4218 103.553 22.2252L105.151 13.5893H107.428L106.962 16.1099V16.1131Z"
              fill="white"
            />
            <path
              d="M120.815 16.1131H122.994L122.834 16.981C123.578 16.2841 124.374 15.9101 125.136 15.9101C125.438 15.9101 125.72 15.9628 125.985 16.0891L125.614 18.0887C125.347 17.9816 125.065 17.9272 124.799 17.9272C124.055 17.9272 123.268 18.338 122.542 19.0701L121.435 25.0511H119.161L120.817 16.1115L120.815 16.1131Z"
              fill="white"
            />
            <path
              d="M134.738 12.5375C135.926 12.5375 137.129 12.8748 138.301 13.5893L137.926 15.624C136.914 14.8903 135.856 14.5035 134.881 14.5035C132.22 14.5035 130.269 16.7924 130.269 19.9571C130.269 21.9775 131.528 23.3009 133.462 23.3009C134.454 23.3009 135.564 22.9972 136.682 22.3355L136.281 24.4997C135.2 25.0367 134.028 25.2685 132.857 25.2685C129.966 25.2685 127.891 23.1586 127.891 20.2448C127.891 15.7566 130.764 12.5391 134.738 12.5391V12.5375Z"
              fill="white"
            />
            <path
              d="M143.125 15.8989C145.36 15.8989 146.939 17.4365 146.939 19.6534C146.939 22.9077 144.775 25.2669 141.743 25.2669C139.489 25.2669 137.929 23.7293 137.929 21.5123C137.929 18.2581 140.076 15.8989 143.125 15.8989ZM142.007 23.3552C143.462 23.3552 144.597 21.8528 144.597 19.8516C144.597 18.6177 143.923 17.8137 142.859 17.8137C141.405 17.8137 140.269 19.3162 140.269 21.3173C140.269 22.5337 140.943 23.3552 142.007 23.3552Z"
              fill="white"
            />
            <path
              d="M114.802 15.8989C117.036 15.8989 118.616 17.4365 118.616 19.6534C118.616 22.9077 116.451 25.2669 113.419 25.2669C111.166 25.2669 109.605 23.7293 109.605 21.5123C109.605 18.2581 111.753 15.8989 114.802 15.8989ZM113.684 23.3552C115.138 23.3552 116.274 21.8528 116.274 19.8516C116.274 18.6177 115.6 17.8137 114.536 17.8137C113.082 17.8137 111.946 19.3162 111.946 21.3173C111.946 22.5337 112.62 23.3552 113.684 23.3552Z"
              fill="white"
            />
            <path
              d="M148.795 22.5321C149.487 22.5321 150.002 23.0324 150.002 23.7485C150.002 24.6068 149.363 25.2685 148.494 25.2685C147.783 25.2685 147.287 24.7506 147.287 24.0346C147.287 23.1762 147.926 22.5321 148.795 22.5321Z"
              fill="white"
            />
            <path
              d="M72.0978 17.9544H74.3433L74.6842 16.1131H72.4387L72.6433 15.0038C72.767 14.3245 73.2792 13.8946 73.9532 13.8946C74.3972 13.8946 74.8571 14.0368 75.3185 14.2878L75.6563 12.464C74.9284 12.1603 74.2196 11.9813 73.5456 11.9813C71.8964 11.9813 70.7372 12.9643 70.4359 14.6282L70.1615 16.1115H68.6534L68.3125 17.9528H69.8206L68.506 25.0511H70.7832L72.0978 17.9528V17.9544Z"
              fill="white"
            />
            <path
              d="M15.3478 0C15.3478 0 4.36779 5.05721 0.756946 16.2745C-1.27445 22.588 0.937726 28.9399 5.60154 30.436C7.93107 31.184 10.1258 30.3609 10.1258 30.3609C7.88191 28.1152 7.46167 23.5566 10.0418 19.3162C12.6409 15.0438 16.4531 12.7134 18.7477 11.5577C18.2466 6.16647 16.193 1.54242 15.3478 0Z"
              fill="white"
            />
            <path
              d="M23.9864 9.60297C23.8564 9.62055 21.6442 10.1112 18.7469 11.5594C18.9309 13.3815 19.145 17.5356 17.7431 21.8033C16.2937 26.2179 13.3806 29.2484 10.125 30.3625C13.4298 33.6567 19.1291 31.6012 21.9899 26.7374C26.5046 19.0605 24.2084 10.4117 23.9864 9.60297Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2231_2888">
              <rect width="150" height="31.9672" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg width="40" height="40" viewBox="0 0 50 70" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_496_25274)">
            <path
              d="M24.9652 0C24.9652 0 7.10433 8.2264 1.23069 26.4732C-2.07372 36.7432 1.52476 47.0756 9.11122 49.5092C12.9006 50.726 16.4707 49.387 16.4707 49.387C12.8206 45.734 12.137 38.3188 16.334 31.421C20.5619 24.4712 26.7631 20.6804 30.4957 18.8006C29.6806 10.0308 26.3401 2.509 24.9652 0Z"
              fill="white"
            />
            <path
              d="M39.0183 15.6208C38.8068 15.6494 35.2083 16.4476 30.4955 18.8032C30.7947 21.7672 31.143 28.5246 28.8626 35.4666C26.5049 42.6478 21.7663 47.5774 16.4705 49.3896C21.8462 54.7482 31.1172 51.4046 35.7707 43.4928C43.1147 31.005 39.3795 16.9364 39.0183 15.6208Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_496_25274">
              <rect width="40" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </Box>
  );

  if (disabledLink) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{logoDark}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {isLight ? logoLight : logoDark}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
  isLight: PropTypes.bool,
};

export default Logo;
