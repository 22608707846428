import { InputAdornment, TextField, Button } from '@mui/material';
import PropTypes from 'prop-types';

import Iconify from '../iconify';

Search.propTypes = {
  size: PropTypes.string,
  onSubmitClicked: PropTypes.func,
  searchValue: PropTypes.string,
  sx: PropTypes.shape({}),
  onSearchClick: PropTypes.func,
};

Search.defaultProps = {
  size: '',
};

export default function Search({ size, onSubmitClicked, searchValue, sx, onSearchClick }) {
 

   return (
      <form onSubmit={onSubmitClicked}>
        <TextField
          sx={{
            ...sx,
            boxShadow: 'none',
            backgroundColor: 'common.white',
            borderRadius: 1,
            fontSize: { sm: 14, lg: 16 },
            lineHeight: '24px',
            fontFamily: 'Whitney',
            color: 'primary.main',
            '& :nth-of-type(1)': {
              paddingRight: 0,
              fontSize: { sm: 14, lg: 16 },
            },
            '& > div > input': {
              color: 'primary.main',
              height: { sm: 11, lg: 23 },
            },
          }}
          required
          variant="outlined"
          name="trackingNumber"
          size={size}
          onClick={onSearchClick}
          value={searchValue}
          placeholder="Enter your tracking number"
          type="text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    boxShadow: 'none',
                    backgroundColor: 'primary.light',
                    color: 'text.primary',
                    alignContent: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                    padding: 0,
                    height: { sm: 28, lg: 40 },
                    width: { sm: 50, lg: 60 },
                    minWidth: 30,
                    '& .MuiButton-startIcon': {
                      margin: '0',
                    },
                  }}
                  startIcon={
                    <Iconify
                      sx={{
                        width: { lg: 25, md: 16, sm: 20 },
                        height: { lg: 25, md: 16, sm: 20 },
                        margin: 0,
                        color: 'text.primary',
                      }}
                      icon="eva:search-fill"
                    />
                  }
                />
              </InputAdornment>
            ),
          }}
        />
      </form> 
  );
}
