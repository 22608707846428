import axios from 'axios';

export default async function putData(url, data, token = null) {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Apikey: process.env.REACT_APP_BACKEND_API_KEY,
      'Access-Control-Allow-Origin': '*',
    },
  };
  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  let result = {};
  try {
    const response = await axios.put(url, data, config);
    result = response;
  } catch (error) {
    console.error('API Error:', error);
    if (error.response?.data?.statusCode == '401') {
      deleteToken();
    }
    throw error;
  }
  return result;
}
