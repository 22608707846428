import getData from 'src/dataFetcher/getDataFetcher';
import postData from "../../dataFetcher/postDataFetcher";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export default async function getTrackingList(parmas, token) {
  const result = await getData(`${backendUrl}tracking-info`, parmas, token);
  return result;
}

export async function getAverageTrackingList(parmas, token) {
  const result = await getData(`${backendUrl}reports/average_intransit_time`, parmas, token);
  return result;
}
export async function getInTransitAlertTrackingList(parmas, token) {
  const result = await getData(`${backendUrl}reports/in-transit-alert`, parmas, token);
  return result;
}

export async function getTrackingInfo(tracking_number,parmas=null, token) {
  const result = await getData(`${backendUrl}tracking-info/${tracking_number}`,parmas, token);
  return result;
}
export async function sendTrackInformationToRefresh(data, token) {
  const result = await postData(`${backendUrl}tracking/refresh`, data, token);
  return result;
}
