import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ onCbNavMenuClick, data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} onClick={onCbNavMenuClick} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};
function NavItem({ onClick, item }) {
  const { title, path, icon, info } = item;
  let linkComponent = {};
  if (path != '/#report_menu') {
    linkComponent = {
      component: RouterLink,
      to: path,
    };
  }
  const isActive = title === 'Reports' && path && location.pathname.includes('/dashboard/reports');
  return icon ? (
    <StyledNavItem
      {...linkComponent}
      onClick={onClick}
      pathname={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightRegular',
          width: 45,
          marginLeft: { sm: -0.9, lg: -1 },
        },
        marginLeft: { sm: -0.6, md: -1, lg: -1 },
      }}
      className={isActive ? 'active' : ''}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  ) : (
    <StyledNavItem
      {...linkComponent}
      onClick={onClick}
      sx={{
        color: 'text.primary',
        marginTop: '10px',
        padding: '0 0 0 35px',
      }}
    >
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}
