import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getReplacementOrdersList from './replacementApi';

const initialState = {
  replaceOrders: [],
  totalCount: 0,
  pageCount: 0,
};

export const fetchReplacementOrders = createAsyncThunk('replacement/fetchReplacementOrders', async (params) => {
  const response = await getReplacementOrdersList(params, params.token);
  return response;
});

const replacementOrderSlice = createSlice({
  name: 'replacement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReplacementOrders.fulfilled, (state, action) => {
        state.replaceOrders = action?.payload?.data;
        state.totalCount = action?.payload?.total_count;
        state.pageCount = action?.payload?.page_count;
      })
      .addCase(fetchReplacementOrders.rejected, (state, action) => {
        // state.replaceOrders = action?.payload?.data;
      });
  },
});

export const getReplacementOrders = (state) => state?.replacementOrder?.replaceOrders;
export const getTotalReplacementOrderCount = (state) => state?.replacementOrder?.totalCount;

export default replacementOrderSlice.reducer;
