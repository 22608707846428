import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useEffect, useState } from 'react';

export default function ReportsLabel() {
  const [text, setText] = useState('');
  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case '/dashboard/reports/preshipment':
        setText('Preshipment Alert');
        break;
      case '/dashboard/reports/intransit':
        setText('In Transit Alert');
        break;
      case '/dashboard/reports/average_intransit_time':
        setText('Average Time InTransit');
        break;
      default:
        setText('');
        break;
    }
  }, [pathname]);

  return (
    <Box sx={{ color: '#000', fontFamily: 'Whitney Semibold', fontSize: '36px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>
    {text}
  </Box>
  );
}
