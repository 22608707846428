import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid } from 'recharts';

export default function AverageDeliveryChart(props) {
  const { onClickToBarChart, reportData, showLoader } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [barData, setBarData] = useState(false);
  const [numericValue, setNumericValue] = useState((70 / 100) * window.innerWidth);
  const data = [
    {
      name: '1 Day',
      TimeinTransit: 1,
      pieceCount: reportData?.data?.reportData?.with1day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with1day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with1day?.runningPercentage ?? 0,
    },
    {
      name: '2 Days',
      TimeinTransit: 2,
      pieceCount: reportData?.data?.reportData?.with2day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with2day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with2day?.runningPercentage ?? 0,
    },
    {
      name: '3 Days',
      TimeinTransit: 3,
      pieceCount: reportData?.data?.reportData?.with3day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with3day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with3day?.runningPercentage ?? 0,
    },
    {
      name: '4 Days',
      TimeinTransit: 4,
      pieceCount: reportData?.data?.reportData?.with4day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with4day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with4day?.runningPercentage ?? 0,
    },
    {
      name: '5 Days',
      TimeinTransit: 5,
      pieceCount: reportData?.data?.reportData?.with5day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with5day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with5day?.runningPercentage ?? 0,
    },
    {
      name: '6 Days',
      TimeinTransit: 6,
      pieceCount: reportData?.data?.reportData?.with6day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with6day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with6day?.runningPercentage ?? 0,
    },
    {
      name: '7 Days',
      TimeinTransit: 7,
      pieceCount: reportData?.data?.reportData?.with7day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with7day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with7day?.runningPercentage ?? 0,
    },
    {
      name: '8 Days',
      TimeinTransit: 8,
      pieceCount: reportData?.data?.reportData?.with8day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with8day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with8day?.runningPercentage ?? 0,
    },
    {
      name: '9 Days',
      TimeinTransit: 9,
      pieceCount: reportData?.data?.reportData?.with9day?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with9day?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with9day?.runningPercentage ?? 0,
    },
    {
      name: '10+Days',
      TimeinTransit: '10+',
      pieceCount: reportData?.data?.reportData?.with10Plusday?.piece ?? 0,
      percentage: reportData?.data?.reportData?.with10Plusday?.percentage ?? 0,
      RunningPer: reportData?.data?.reportData?.with10Plusday?.runningPercentage ?? 0,
    },
  ];
  let filteredData;
  if (barData) {
    filteredData = data.filter((item) => item.pieceCount > 0);
    data.length = 0;
    data.push(...filteredData);
  }
  const StyledRoot = styled('div')(() => ({
    maxWidth: '100%',
    height: '400px',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    paddingTop: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderStartEndRadius: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  }));

  useEffect(() => {
    // to update barChart Width
    const updateNumericValue = () => {
      let newNumericValue = (70 / 100) * window.innerWidth;
      if (newNumericValue < 550) {
        newNumericValue += 50;
      }
      setNumericValue(newNumericValue);
    };
    window.addEventListener('resize', updateNumericValue);
    return () => {
      window.removeEventListener('resize', updateNumericValue);
    };
    updateNumericValue();
  }, []);

  useEffect(() => {
    setBarData(reportData?.data?.reportData?.totalDeliveredOrders);
    barData ? handleClick(filteredData[0], 0) : handleClick(data[0], 0);
  }, [reportData, barData]);

  function handleClick(e, index) {
    setActiveIndex(index);
    onClickToBarChart(e);
  }

  function handleLabelClick(e, index) {
    setActiveIndex(index);
    let clickedData = filteredData.find((item) => item.name === e.value);
    if (clickedData) {
      onClickToBarChart({
        TimeinTransit: clickedData.TimeinTransit,
        pieceCount: clickedData.pieceCount,
        percentage: clickedData.percentage,
        RunningPer: clickedData.RunningPer,
      });
    }
  }

  return (
    <StyledRoot>
      {!showLoader && !barData && (
        <Typography variant="h5" sx={{ color: 'primary.main', width: '100%', position: 'absolute', left: '40%' }}>
          No data found
        </Typography>
      )}
      <BarChart className="barchartStyle" data={data} width={numericValue} height={396}>
        <CartesianGrid stroke="transparent" />
        <XAxis
          dataKey="name"
          axisLine={{ stroke: '#D9D9D9', width: '2px' }}
          tick={{
            fill: '#000',
            fontFamily: 'Whitney Semibold',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '111%',
            letterSpacing: '0.15px',
          }}
          onClick={(e, i) => handleLabelClick(e, i)}
        />
        <YAxis
          axisLine={{ stroke: '#D9D9D9', width: '2px' }}
          tick={{
            fill: '#000',
            fontFamily: 'Whitney Semibold',
            fontSize: 12,
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '111%',
            letterSpacing: '0.15px',
          }}
        />
        <Bar dataKey="pieceCount" onClick={(e, i) => handleClick(e, i)} barSize={{ sm: 80, md: 100 }}>
          {data.map((entry, index) => (
            <Cell cursor="pointer" fill={index === activeIndex ? '#479DA6' : '#D9D9D9'} key={`cell-${index}`} />
          ))}
        </Bar>
      </BarChart>
    </StyledRoot>
  );
}
AverageDeliveryChart.PropTypes = {
  onClickToBarChart: PropTypes.func,
  reportData: PropTypes.object,
  showLoader: PropTypes.bool,
};
